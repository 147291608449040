import moment from "moment";
import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import LinkOpener from "../../../components/actions/LinkOpener";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import inputTypes from "../../../constants/form/inputTypes";
import {
  createTemplate,
  deleteTemplate,
  duplicateTemplate,
  getTemplate,
  updateTemplate,
} from "../../../services/api/queries/template";
import templateFields from "../formFields/templateFields";

const templateListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Template Name",
        accessor: "name",
      },
      {
        Header: "Created Date",
        accessor: "created_at",
        Cell: ({ cell }) =>
          moment(cell.row.original.created_at).format("MMM DD, YYYY"),
      },
      {
        Header: "Last Update",
        accessor: "updated_at",
        Cell: ({ cell }) =>
          moment(cell.row.original.updated_at).format("MMM DD, YYYY"),
      },
      {
        Header: "Total Questions",
        accessor: "total_question",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateTemplate}
              fields={templateFields.filter((field) => field.editable)}
              heading={"Edit a Category!"}
              openerText="Edit"
              defaultValues={defaultValues}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Local agent updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => {
            return defaultValues.is_sectionize ? (
              <LinkOpener
                key="sections"
                openerText="Section List"
                navigateTo={`/pages/quiz-management/section?template_id=${defaultValues.id}`}
                openerClassName="w-100"
              />
            ) : (
              <LinkOpener
                key="questions"
                openerText="Question List"
                navigateTo={`/pages/quiz-management/question?template_id=${defaultValues.id}`}
                openerClassName="w-100"
              />
            );
          },
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={duplicateTemplate}
              fields={[
                {
                  className: "mb-3",
                  inputType: inputTypes.TEXT,
                  key: "name",
                  labelText: "Template Name",
                  placeHolder: "Enter Template Name",
                  rules: { required: true },
                },
              ]}
              heading="Duplicate Template"
              openerText="Make Duplicate"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              size="sm"
              defaultValues={defaultValues}
              successMessage="Duplicate Successful!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <LinkOpener
              key="questions"
              openerText="Preview Template"
              navigateTo={`/pages/view-template/${defaultValues.id}`}
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              queryService={deleteTemplate}
              value={defaultValues}
              refreshEvent="refresh_table"
              confirmationMessage="Do you really wanna delete this template?"
              heading="Delete Template"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createTemplate}
        fields={templateFields}
        heading={"Create a Template!"}
        openerText="+ Add Template"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Template Created!"
      />,
    ],
    queryService: getTemplate,
    addButtonText: "+ Add Template",
  };
};

export default templateListViewMeta;
