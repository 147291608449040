import React, { useCallback, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

const CreatableSelectInput = ({
  meta,
  defaultValue,
  formInstance,
  isWatchSubscribed,
  dependencyValues,
}) => {
  const {
    key,
    rules = {},
    formatCreateLabel,
    createOptionPosition,
    lookupQuery,
  } = meta;
  const { setValue, control } = formInstance;
  const [dropdownMeta, setDropdownMeta] = useState(meta.options || []);
  const [display, setDisplay] = useState(true);

  const performLookupQuery = useCallback(
    async (data) => {
      try {
        const res = await lookupQuery(data);
        setDropdownMeta(res?.length ? res : []);
        if (defaultValue) setValue(key, defaultValue);
      } catch (err) {
        setDropdownMeta([]);
        throw err;
      }
    },
    [defaultValue, key, lookupQuery, setValue]
  );

  useEffect(() => {
    if (!lookupQuery) return;
    performLookupQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performLookupQuery]);

  const toggleVisibilityOfSelect = useCallback((condition) => {
    if (condition) setDisplay(false);
    else setDisplay(true);
  }, []);

  useEffect(() => {
    if (!meta.dependencies?.length || !Object.keys(dependencyValues).length)
      return;
    meta?.onDependencyValueChange(dependencyValues, meta.key, {
      performLookupQuery,
      toggleVisibilityOfSelect,
    });
  }, [meta, performLookupQuery, dependencyValues, toggleVisibilityOfSelect]);

  const handleSelect = useCallback(
    (value) => {
      setValue(key, value);
    },
    [key, setValue]
  );

  useEffect(() => {
    if (isWatchSubscribed) handleSelect(defaultValue);
  }, [defaultValue, handleSelect, isWatchSubscribed]);

  return (
    display && (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={key}
        rules={rules}
        shouldUnregister
        render={({ field: { onChange, value, ref } }) => (
          <CreatableSelect
            inputRef={ref}
            classNamePrefix="addl-class"
            formatCreateLabel={formatCreateLabel}
            createOptionPosition={createOptionPosition}
            options={dropdownMeta}
            value={dropdownMeta.find((c) => c.value === value)}
            onChange={(val) => {
              onChange(val?.value);
            }}
            isClearable
          />
        )}
      />
    )
  );
};

export default CreatableSelectInput;
