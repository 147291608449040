import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createSection = async (data) => {
  const payload = {
    name: data.name,
    // name_bn: data.name_bn,
    total_marks: data.total_marks,
    template: data.template_id,
  };
  return await adminApi({
    endpoint: endpoints.SECTION,
    method: "post",
    data: payload,
  });
};

export const updateSection = async (data) => {
  const payload = {
    name: data.name,
    // name_bn: data.name_bn,
    total_marks: data.total_marks,
  };
  return await adminApi({
    endpoint: `${endpoints.SECTION}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const deleteSection = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.SECTION}${data.id}/`,
    method: "delete",
  });
};

export const getSections = async (data) => {
  return await adminApi({
    endpoint: endpoints.SECTION,
    method: "get",
    params: data,
  });
};
