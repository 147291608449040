import inputTypes from "constants/form/inputTypes";

const categoryFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Category Name",
    placeHolder: "Category Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name_bn",
    labelText: "Category Bangla Name",
    placeHolder: "Category Bangla Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.CHECKBOX,
    key: "show_in_landing",
    labelText: "Show in Landing Page",
  },
];

export default categoryFields;
