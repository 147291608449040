import moment from "moment";
import {
  getFreeExamParticipants,
  downloadExamParticipantReport,
} from "../../../services/api/queries/freeExam";
import DownloadButton from "components/actions/DownloadButton";

const freeExamParticipatnsListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
      },
      {
        Header: "Last Attempt",
        accessor: "updated_at",
        Cell: ({ value }) => moment(value).format("MMMM Do YYYY, h:mm:ss a"),
      },
      {
        Header: "Exam",
        accessor: "free_exam.name",
      },
    ],
    actions: [<DownloadButton submitService={downloadExamParticipantReport} />],
    queryService: getFreeExamParticipants,
  };
};

export default freeExamParticipatnsListViewMeta;
