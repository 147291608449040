import inputTypes from "../../../constants/form/inputTypes";

export const orderFilterMeta = [
  {
    className: "col-5",
    inputType: inputTypes.TEXT,
    key: "search",
    labelText: "Phone Number",
  },
  {
    className: "col-5 mt-1",
    inputType: inputTypes.DATE,
    key: "created_at_after",
    labelText: "From Date",
    isID: true,
  },
  {
    className: "col-5 mt-1",
    inputType: inputTypes.DATE,
    key: "created_at_before",
    labelText: "To Date",
    isID: true,
  },
  {
    className: "col-5 mt-1",
    inputType: inputTypes.SELECT,
    key: "status",
    labelText: "Status",
    isID: true,
    options: [
      { label: "Pending", value: "pending" },
      { label: "Confirmed", value: "confirmed" },
      { label: "Cancelled", value: "cancelled" },
    ],
  },
];
