import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createBlogCategory,
  deleteBlogCategory,
  getBlogCategory,
  updateBlogCategory,
} from "../../../services/api/queries/blogCategory";
import categoryFields from "../formFields/categoryFields";

const blogCategoryListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Bangla Name",
        accessor: "name_bn",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateBlogCategory}
              fields={categoryFields}
              heading={"Edit a Category!"}
              openerText="Edit"
              defaultValues={defaultValues}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Local agent updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteBlogCategory}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this category?"
              heading="Delete Category"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createBlogCategory}
        fields={categoryFields}
        heading={"Create a Category!"}
        openerText="+ Add Category"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Category Created!"
      />,
    ],
    queryService: getBlogCategory,
    addButtonText: "+ Add Category",
    modelName: "blogcategory",
  };
};

export default blogCategoryListViewMeta;
