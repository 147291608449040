import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import couponListViewMeta from "../../../configs/meta/listViewMeta/couponListViewMeta";

const Coupon = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView meta={couponListViewMeta()} title="Coupon List" />
    </Container>
  </React.Fragment>
);

export default Coupon;
