import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ListView from "../../../components/listView/ListView";
import { courseFilterMeta } from "../../../configs/meta/filterMeta/courseFilterMeta";
import courseListViewMeta from "../../../configs/meta/listViewMeta/courseListViewMeta";

const Course = () => (
  <React.Fragment>
    <Helmet title="Course Page" />
    <Container fluid className="p-0">
      <ListView
        filters={courseFilterMeta}
        meta={courseListViewMeta()}
        title="Course List"
      />
    </Container>
  </React.Fragment>
);

export default Course;
