import inputTypes from "constants/form/inputTypes";

const topicFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Topic Name",
    placeHolder: "Enter Topic Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "total_lecture",
    labelText: "Number of Lectures",
    placeHolder: "Enter the Number of Lectures",
    rules: { required: true },
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.NUMBER,
    key: "hours",
    labelText: "Hours",
    placeHolder: "Enter Hours",
    rules: { required: true },
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.NUMBER,
    key: "minutes",
    labelText: "Minutes",
    placeHolder: "Enter Minutes",
    rules: { required: true },
  },
];

export default topicFields;
