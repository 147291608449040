import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createFqType = async (data) => {
  return await adminApi({
    endpoint: endpoints.FAQ_TYPE,
    method: "post",
    data: data,
  });
};

export const getFaqType = async (data) => {
  return await adminApi({
    endpoint: endpoints.FAQ_TYPE,
    method: "get",
    params: data,
  });
};

export const updateFaqType = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.FAQ_TYPE}${data.id}/`,
    method: "patch",
    data: data,
  });
};

export const deleteFaqType = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.FAQ_TYPE}${data.id}/`,
    method: "delete",
  });
};

export const createFaq = async (data) => {
  return await adminApi({
    endpoint: endpoints.FAQ,
    method: "post",
    data: data,
  });
};

export const getFaq = async (data) => {
  return await adminApi({
    endpoint: endpoints.FAQ,
    method: "get",
    params: data,
  });
};

export const updateFaq = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.FAQ}${data.id}/`,
    method: "patch",
    data: data,
  });
};

export const deleteFaq = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.FAQ}${data.id}/`,
    method: "delete",
  });
};

export const getFaqTypeForDropdown = async (data) => {
  const res = await adminApi({
    endpoint: endpoints.FAQ_TYPE,
    method: "get",
    params: { page_size: 0 },
  });

  return res.data?.map((faq) => ({
    value: faq.id,
    label: faq.name,
  }));
};
