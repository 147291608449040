import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const createLecture = async (data) => {
  const payload = {
    ...data,
    order_in_topic: 1000,
  };
  return await adminApi({
    endpoint: endpoints.COURSE_LECTURE,
    method: "post",
    data: payload,
  });
};

export const updateLecture = async (data) => {
  const payload = {
    ...data,
    ...(data.l_type === "live_class"
      ? {
          joining_date: data.joining_date,
          joining_link: data.joining_link,
          video_url: "",
        }
      : { joining_date: null, joining_link: "", video_url: data.video_url }),
  };
  return await adminApi({
    endpoint: `${endpoints.COURSE_LECTURE}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getLectureById = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.COURSE_LECTURE}${data.id}/`,
    method: "get",
  });
};

export const deleteLecture = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.COURSE_LECTURE}${data.id}/`,
    method: "delete",
  });
};

export const getLectures = async (data) => {
  return await adminApi({
    endpoint: endpoints.COURSE_LECTURE,
    method: "get",
    params: data,
  });
};
