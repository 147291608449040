import inputTypes from "constants/form/inputTypes";

const questionFields = [
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "name",
    labelText: "Question Name",
    placeHolder: "Enter Question Name",
    rules: { required: true },
  },
  {
    className: "col-5 mb-3",
    inputType: inputTypes.SELECT,
    key: "q_type",
    labelText: "Question Type",
    placeholder: "Question Type",
    options: [
      {
        label: "Single Choice",
        value: "single_choice",
      },
      {
        label: "Multiple Choice",
        value: "multiple_choice",
      },
      {
        label: "Written",
        value: "written",
      },
    ],
  },
  {
    className: "mb-3",
    inputType: inputTypes.NUMBER,
    key: "mark",
    defaultValue: 1,
    labelText: "Mark",
    placeHolder: "Enter Marks",
    rules: {
      required: true,
      min: { value: 0, message: "Question mark can't be negative." },
    },
  },
  {
    className: "mb-2",
    inputType: inputTypes.RADIO,
    key: "has_negative_mark",
    childrenClassName: "d-flex",
    labelText: "Has Negative Marks",
    defaultValue: "true",
    options: [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
    dependencies: ["q_type"],
    hide: true,
    onDependencyValueChange: ({ q_type }, key, { toggleVisibility }) => {
      toggleVisibility("has_negative_mark", q_type === "written");
    },
  },
  {
    className: "mb-3",
    inputType: inputTypes.NUMBER,
    key: "deduction_precentage",
    labelText: "Deduction Percentage",
    placeHolder: "Enter Deduction Percentage",
    dependencies: ["has_negative_mark", "q_type"],
    hide: true,
    defaultValue: 25,
    onDependencyValueChange: (
      { has_negative_mark, q_type },
      key,
      { toggleVisibility }
    ) => {
      toggleVisibility(
        "deduction_precentage",
        has_negative_mark === "false" || q_type === "written"
      );
    },
  },
  {
    className: "col-10 mb-3",
    inputType: inputTypes.FILE,
    key: "image",
    labelText: "Question Image",
    multiple: false,
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "answer_explanation",
    labelText: "Answer Explanation",
    placeHolder: "Enter Explanation",
  },
  {
    inputType: inputTypes.TEXT,
    key: "video_explanation",
    labelText: "Video Explanation",
    placeHolder: "Enter URL",
  },
  {
    className: "my-3",
    inputType: inputTypes.CHECKBOX,
    key: "out_of_short_syllabus",
    labelText: "Out Of Short Syllabus",
  },
  {
    className: "my-3",
    inputType: inputTypes.CHECKBOX,
    key: "no_right_answer",
    labelText: "No Right Answer",
  },

  {
    className: "row list-input",
    inputType: inputTypes.QUESTION_INPUT,
    key: "options",
    labelPrefix: "Options",
    addButtonText: "+ Add an Option",
    addButtonClassName: "mt-3",
    listItemClassName: "row",
    dependencies: ["q_type"],
    onDependencyValueChange: ({ q_type }, key, { toggleVisibility }) => {
      toggleVisibility("options", q_type === "written");
    },
  },
];

export default questionFields;
