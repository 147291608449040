import { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import { eventBus } from "../../services/eventBus";

function ConfirmActionButton({
  openerText,
  openerClassName = "",
  confirmButtonName,
  queryService,
  value,
  confirmationMessage,
  heading,
  refreshEvent,
  successMessage,
  errorMessage,
}) {
  const notyf = useContext(NotyfContext);
  const handleConfirm = async () => {
    try {
      const res = await queryService(value);
      if (!res.success) throw res;
      if (refreshEvent) eventBus.publish(refreshEvent);
      const message = successMessage || "Success!";
      notyf.open({
        type: "success",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    } catch (error) {
      const message = error.error || errorMessage || "Something went wrong";
      notyf.open({
        type: "danger",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  };
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>{heading}</Modal.Header>
        <Modal.Body className="text-center m-3">
          <p className="mb-0">{confirmationMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>{" "}
          <Button
            variant="primary"
            onClick={() => {
              setShowModal(false);
              handleConfirm();
            }}
          >
            {confirmButtonName}
          </Button>
        </Modal.Footer>
      </Modal>
      <p
        className={`${openerClassName} mb-0`}
        onClick={() => setShowModal(true)}
      >
        {openerText}
      </p>
    </>
  );
}

export default ConfirmActionButton;
