import React from "react";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import ListView from "../../../components/listView/ListView";
import questionListViewMeta from "../../../configs/meta/listViewMeta/questionListViewMeta";

const QuestionList = () => {
  const [searchParams] = useSearchParams();

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <ListView
          meta={questionListViewMeta({
            template: searchParams.get("template_id"),
            section: searchParams.get("section_id"),
          })}
          title="Question List"
          pagination={false}
        />
      </Container>
    </React.Fragment>
  );
};
export default QuestionList;
