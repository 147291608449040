import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const createExam = async (data) => {
  const payload = {
    ...data,
    order_in_topic: 1000,
    solution_pdf: data.solution_pdf[0],
    duration:
      data.duration_select === "time_duration"
        ? `${data.hours || 0}:${data.minutes || 0}`
        : null,
  };
  return await adminApi({
    endpoint: endpoints.EXAMS,
    method: "post",
    data: payload,
  });
};

export const getExamById = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.EXAMS}${data.id}/`,
    method: "get",
  });
};

export const updateExam = async (data) => {
  const payload = {
    ...data,
    solution_pdf: Array.isArray(data.solution_pdf)
      ? data.solution_pdf[0]
      : data.solution_pdf,
    duration:
      data.duration_select === "time_duration"
        ? `${data.hours || 0}:${data.minutes || 0}`
        : null,
    start_date: data.start_select === "instant" ? null : data.start_date,
  };
  return await adminApi({
    endpoint: `${endpoints.EXAMS}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getExams = async (data) => {
  return await adminApi({
    endpoint: endpoints.EXAMS,
    method: "get",
    params: data,
  });
};

export const deleteExam = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.EXAMS}${data.id}/`,
    method: "delete",
  });
};
