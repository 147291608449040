import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ListView from "../../../components/listView/ListView";
import { salesFilterMeta } from "../../../configs/meta/filterMeta/salesFilterMeta";
import salesManagementListViewMeta from "../../../configs/meta/listViewMeta/salesManagementListViewMeta";

const SalesManagement = () => (
  <React.Fragment>
    <Helmet title="Sales Page" />
    <Container fluid className="p-0">
      <ListView
        filters={salesFilterMeta}
        meta={salesManagementListViewMeta()}
        title="Sales List"
      />
    </Container>
  </React.Fragment>
);

export default SalesManagement;
