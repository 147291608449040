import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";
import FormBuilder from "components/form/formBuilder/FormBuilder";
import loginFields from "configs/meta/formFields/loginFields";
import { RESET_PASSWORD } from "../../constants/routes";
import { Alert } from "react-bootstrap";

function SignIn() {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { signIn } = useAuth();
  const submitButton = {
    className: "btn btn-primary btn-lg rounded-0",
    label: "Login",
    isDisabled: false,
  };

  const handleSubmit = async (data) => {
    try {
      await signIn(data.username, data.password);
      navigate("/");
    } catch (error) {
      const message = error.error || "Something went wrong";
      notyf.open({
        type: "danger",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  };

  return (
    <>
      <Alert className="my-3" variant="primary">
        <div className="alert-message">
          Type <strong>Username</strong> and <strong>password</strong> to sign
          in
        </div>
      </Alert>
      <FormBuilder
        onSubmit={handleSubmit}
        fields={loginFields}
        submitButton={submitButton}
        className=""
        doNotReset={true}
        actionsPrev={[
          <div>
            <Link to={RESET_PASSWORD} className="display-block">
              Forgot Password
            </Link>
            <br />
          </div>,
        ]}
      />
    </>
  );
}

export default SignIn;
