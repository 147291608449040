/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { joinForum } from "../../../services/api/queries/forum";
import { getCourse } from "../../../services/api/queries/course";

const Join = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getCourse({ status: "published", page_size: 0 }).then(
      (info) => {
        const { data } = info;
        setCourses(data);
        setIsLoading(false);
        return info;
      }
    );
    return res;
  }, []);

  const performJoin = useCallback(
    (id) => {
      setIsLoading(true);
      const res = joinForum({ id })
        .then((info) => {
          const res = info;
          if (!res.success) throw res;
          navigate(`/discussions/forum/${id}/${res.data.id}`);
          return info;
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
      return res;
    },
    [navigate]
  );

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="Courses  Page" />
      <Loader isLoading={isLoading}>
        <Container fluid className="p-0 my-courses">
          <h1 className="h3 mb-3 top-title">Course List</h1>
          {!courses?.length ? (
            <div className="d-flex justify-content-center align-items-center my-5">
              <div className="no-course-card">
                <div className="mx-auto text-center">
                  <h3 className="title">You Have No Enrolled Course</h3>
                  <button className="btn_primary btn_md">Enroll Now</button>
                </div>
              </div>
            </div>
          ) : (
            <Row>
              {courses.map((course, index) => (
                <div
                  key={`all-courses-card-${index + 1}`}
                  className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3"
                >
                  <div className="card mb-5 pb-3 mb-5 mx-sm-auto text-center">
                    <div className="">
                      <div className="img-container">
                        <img
                          className="card-img-top w-100"
                          src={course.cover_photo}
                          alt="Card cap"
                        />
                      </div>
                      <div className="card-body mb-0 pb-2">
                        <h2 className="course-title ellipsis-one-line">
                          {course.name}
                        </h2>
                        <p className="course-description ellipsis-two-line">
                          {course.short_description}
                        </p>
                      </div>
                    </div>
                    <div className="buttons-container mx-auto d-flex justify-content-evenly mb-3 w-100">
                      <button
                        onClick={() => performJoin(course.id)}
                        className="btn_sm btn_primary"
                      >
                        Join Discussion
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          )}
        </Container>
      </Loader>
    </React.Fragment>
  );
};
export default Join;
