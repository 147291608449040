import inputTypes from "constants/form/inputTypes";

const circularFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "company_name",
    labelText: "Company Name",
    placeHolder: "Company Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "company_name_bn",
    labelText: "Company Bangla Name",
    placeHolder: "Company Bangla Name",
    rules: { required: true },
  },
  {
    className: "pb-3",
    inputType: inputTypes.FILE,
    key: "company_logo",
    labelText: "Company Logo:",
    multiple: false,
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "title",
    labelText: "Title Name",
    placeHolder: "Title Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "title_bn",
    labelText: "Title Bangla Name",
    placeHolder: "Title Bangla Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "short_description",
    labelText: "Short Description",
    placeHolder: "Short Description",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "short_description_bn",
    labelText: "Short Description (Bangla)",
    placeHolder: "Short Description (Bangla)",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "description",
    labelText: "Description",
    placeHolder: "Description",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "description_bn",
    labelText: "Description Bangla",
    placeHolder: "Description Bangla",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "apply_link",
    labelText: "Apply Link",
    placeHolder: "Apply Link",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "c_type",
    labelText: "Circular Type",
    placeHolder: "Circular Type",
    isMulti: true,
    rules: { required: true },
    options: [
      { label: "Defense", value: "defense" },
      { label: "Paramilitary", value: "paramilitary" },
      { label: "Civilian", value: "civilian" },
      { label: "Private", value: "private" },
    ],
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "job_type",
    labelText: "Job Type",
    placeHolder: "Job Type",
    isMulti: true,
    rules: { required: true },
    options: [
      { label: "Full Time", value: "full_time" },
      { label: "Part Time", value: "part_time" },
      { label: "Remote", value: "remote" },
      { label: "Internship", value: "internship" },
      { label: "Contract", value: "contract" },
      { label: "Training", value: "training" },
    ],
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "work_level",
    isMulti: true,
    labelText: "Work Level",
    placeHolder: "Work Level",
    rules: { required: true },
    options: [
      { label: "Student", value: "student" },
      { label: "Entry Level", value: "entry_level" },
      { label: "Mid Level", value: "mid_level" },
      { label: "Senior Level", value: "senior_level" },
      { label: "Director", value: "director" },
      { label: "VP", value: "vp" },
    ],
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "exp_level",
    isMulti: true,
    labelText: "Experience Level",
    placeHolder: "Experience Level",
    rules: { required: true },
    options: [
      { label: "No Experience", value: "no_exp" },
      { label: "1-2 Years", value: "1_2_years" },
      { label: "2-5 Years", value: "2_5_years" },
      { label: "5-10 Years", value: "5_10_years" },
      { label: "10+ Years", value: "10+" },
    ],
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "status",
    labelText: "Status",
    placeHolder: "Status",
    rules: { required: true },
    options: [
      { label: "Published", value: "published" },
      { label: "Draft", value: "draft" },
    ],
  },
];

export default circularFields;
