import React from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "react-bootstrap";

import usePalette from "../../../hooks/usePalette";
import { externalTooltipHandler } from "./toolTip";

const BarChart = ({ chartData, toolTipData }) => {
  const palette = usePalette();

  const data = {
    labels: chartData?.map((d) => d.month?.slice(0, 3)),
    datasets: [
      {
        label: "Total Enrollment",
        backgroundColor: palette.primary,
        borderColor: palette.primary,
        hoverBackgroundColor: palette.primary,
        hoverBorderColor: palette.primary,
        data: chartData?.map((d) => d.count),
        barPercentage: 0.4,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 5,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            min: 0,
            max: 100,
            stepSize: 20,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "title",
      },
      tooltip: {
        // enabled: toolTipData ? false : true,
        position: "nearest",
        external: function (context) {
          externalTooltipHandler(context, toolTipData);
        },
      },
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">Sales / Revenue</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <Bar data={data} options={options} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BarChart;
