import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createQuestion,
  deleteQuestion,
  getQuestion,
  updateOption,
  updateQuestion,
} from "../../../services/api/queries/question";
import optionUpdateFields from "../formFields/optionUpdateFields";
import questionFields from "../formFields/questionFields";

const questionTypeMap = {
  single_choice: "Single Choice",
  multiple_choice: "Multiple Choice",
  written: "Written",
};

const questionListViewMeta = ({ template, section }) => {
  return {
    columns: [
      {
        Header: "Serial",
        accessor: "index",
      },
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Question",
        accessor: "name",
        Cell: ({ value }) => (
          <ReactMarkdown children={value} rehypePlugins={[rehypeRaw]} />
        ),
      },
      {
        Header: "Question type",
        accessor: "q_type",
        Cell: ({ value }) => questionTypeMap[value],
      },
      {
        Header: "Marks",
        accessor: "mark",
      },
      {
        Header: "Out Of Short Syllabus",
        accessor: "out_of_short_syllabus",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateQuestion}
              fields={questionFields.map((field) => {
                if (field.key === "q_type") {
                  return { ...field, disabled: true };
                }
                if (field.key === "deduction_precentage") {
                  return {
                    ...field,
                    hide: !defaultValues.has_negative_mark,
                  };
                } else if (
                  field.key === "options" ||
                  field.key === "has_negative_mark"
                ) {
                  return {
                    ...field,
                    hide: defaultValues.q_type === "written",
                  };
                }
                return field;
              })}
              heading={"Edit a Question!"}
              openerText="Edit"
              defaultValues={{
                ...defaultValues,
                image: defaultValues.image ? [defaultValues.image] : [],
                has_negative_mark: defaultValues.has_negative_mark
                  ? "true"
                  : "false",
              }}
              refreshEvent="refresh_table"
              size="xl"
              doNotReset={true}
              successMessage="Question updated!"
            />
          ),
          (defaultValues) => (
            <ModalFormOpener
              key="edit-options"
              submitService={updateOption}
              fields={optionUpdateFields}
              heading={"Update Options!"}
              openerText="Options Update"
              defaultValues={{
                ...defaultValues,
              }}
              refreshEvent="refresh_table"
              size="xl"
              doNotReset={true}
              successMessage="Options updated!"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteQuestion}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this question?"
              heading="Delete Question"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createQuestion}
        fields={questionFields}
        heading={"Create a Question!"}
        openerText="+ Add Question"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="xl"
        successMessage="Question Created!"
      />,
    ],
    modelName: "question",
    queryService: (params) => getQuestion({ ...params, template, section }),
    addButtonText: "+ Add Question",
  };
};

export default questionListViewMeta;
