import moment from "moment";
import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createFqType,
  deleteFaqType,
  getFaqType,
  updateFaqType,
} from "../../../services/api/queries/faq";
import faqTypeFields from "../formFields/faqTypeFields";

const faqTypeListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Name Bangla",
        accessor: "name_bn",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateFaqType}
              fields={faqTypeFields}
              heading={"Edit a Faq Type!"}
              openerText="Edit"
              defaultValues={{
                ...defaultValues,
                start_date: moment(defaultValues.start_date).format(
                  "YYYY-MM-DD"
                ),
                end_date: moment(defaultValues.end_date).format("YYYY-MM-DD"),
                courses: defaultValues.all_courses
                  ? ["select_all"]
                  : defaultValues.courses,
              }}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Faq Type updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteFaqType}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this Faq Type?"
              heading="Delete Faq Type"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createFqType}
        fields={faqTypeFields}
        heading={"Create a Faq Type!"}
        openerText="+ Add Faq Type"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Faq Type Created!"
      />,
    ],
    queryService: getFaqType,
    addButtonText: "+ Add Faq Type",
    modelName: "faq type",
  };
};

export default faqTypeListViewMeta;
