/* eslint-disable prettier/prettier */
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import avatar1 from "../../assets/img/avatars/avatar.jpg";

const Discussion = ({ question, course_id }) => {
  return (
    <>
      <div className="p-3 pb-0 discussion">
        <h1 className="discussion__title">{question?.title}</h1>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <img
              src={question?.asked_by?.image || avatar1}
              alt="img"
              width={50}
              height={50}
              className="rounded-circle object-fit-cover"
            />
            <div className="d-flex flex-column ms-2">
              {" "}
              <span className="font-weight-bold">
                {(!question?.asked_by?.first_name && !question?.asked_by?.last_name) ? 'Anonymous User': `${question?.asked_by?.first_name} ${question?.asked_by?.last_name}`} 
                </span>{" "}
              <small className="me-2">
                {moment(question?.created_at).format("MMM DD, YYYY hh:mm a")}
              </small>{" "}
            </div>
          </div>
        </div>{" "}
        <div className="p-2">
          <p className="text-justify">{question?.description}</p>
          {question?.image ? (
            <div className="image-container mx-auto text-center my-2 py-4 ">
              <img src={question.image} alt="img" />
            </div>
          ) : null}
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-row muted-color">
              {" "}
              <span>{question?.total_answer} Answers</span>
            </div>
            <Link
              className="btn btn-see-answers"
              to={`/discussions/answers/${course_id}/${question.id}`}
            >
              See answers
            </Link>
          </div>
        </div>
        {/* <hr className="mb-0" /> */}
      </div>
    </>
  );
};

export default Discussion;
