import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const getStudents = async (data) => {
  const params = { ...data, u_type: "student" };
  return await adminApi({
    endpoint: `${endpoints.USERS}`,
    method: "get",
    params: params,
  });
};
