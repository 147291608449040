import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import siteInfoListViewMeta from "configs/meta/listViewMeta/siteInfoListViewMeta";

const SiteInfo = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView
        meta={siteInfoListViewMeta()}
        title="Site Info"
        pagination={false}
      />
    </Container>
  </React.Fragment>
);

export default SiteInfo;
