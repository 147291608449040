import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createSubCategory,
  deleteSubCategory,
  getSubCategory,
  updateSubCategory,
} from "../../../services/api/queries/subcategory";
import subCategoryFields from "../formFields/subCategoryFields";

const subCategoryListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Bangla Name",
        accessor: "name_bn",
      },
      {
        Header: "Category",
        accessor: "category.name",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateSubCategory}
              fields={subCategoryFields}
              heading={"Edit a Category!"}
              openerText="Edit"
              parentId="modal_span"
              defaultValues={{
                ...defaultValues,
                category: defaultValues.category.id,
                name: defaultValues.name,
                name_bn: defaultValues.name_bn,
              }}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Sub-category updated!"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteSubCategory}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this Sub-category?"
              heading="Delete Sub-category"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createSubCategory}
        fields={subCategoryFields}
        heading={"Create a Category!"}
        openerText="+ Add Sub-category"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Created Sub-category!"
        openerClassName="btn btn-primary"
      />,
    ],
    queryService: getSubCategory,
    modelName: "subcategory",
  };
};

export default subCategoryListViewMeta;
