import inputTypes from "constants/form/inputTypes";

const bookReviewFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "reviewer_name",
    labelText: "Reviewer Name",
    placeHolder: "Enter Reviewer Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "reviewer_name_bn",
    labelText: "Reviewer Name (Bangla)",
    placeHolder: "Enter Reviewer Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "review",
    labelText: "Review",
    placeHolder: "Enter Review",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "review_bn",
    labelText: "Review (Bangla)",
    placeHolder: "Enter Review",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.NUMBER,
    key: "rating",
    labelText: "Rating",
    placeHolder: "Enter Rating",
    rules: {
      required: true,
      max: { value: 5, message: "Input value must be between 1 and 5" },
      min: { value: 1, message: "Input value must be between 1 and 5" },
    },
  },

  {
    className: "pb-3",
    inputType: inputTypes.FILE,
    key: "reviewer_image",
    labelText: "Reviewer Image:",
    rules: { required: true },
    multiple: false,
  },

  {
    className: "col-4 pb-3",
    inputType: inputTypes.SELECT,
    key: "status",
    labelText: "Status",
    placeholder: "Select Status",
    options: [
      {
        label: "Pending",
        value: "pending",
      },
      {
        label: "Accepted",
        value: "accepted",
      },
      {
        label: "Rejected",
        value: "rejected",
      },
    ],
  },

  {
    className: "pb-3",
    inputType: inputTypes.CHECKBOX,
    key: "show_in_landing",
    labelText: "Show in Landing Page:",
    rules: { required: false },
  },
];

export default bookReviewFields;
