import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUrlSearchParams } from "../../utils/urls";
import ModalForm from "./ModalForm";

function ModalFormOpener({
  submitService,
  fields,
  openerText,
  heading = "",
  submitButtonText = "Submit",
  defaultValues,
  doNotReset,
  renderOpener,
  successMessage,
  errorMessage,
  appendPayload,
  openerClassName = "",
  refreshEvent,
  submitButton = {
    className: "btn btn-primary rounded-0",
    label: "Submit",
    isDisabled: false,
  },
  size = "lg",
}) {
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [shouldRenderOpener, setShouldRenderOpener] = useState(true);

  useEffect(() => {
    const checkRenderOpener = async () => {
      setShouldRenderOpener(false);
      try {
        const res = await renderOpener();
        setShouldRenderOpener(res);
      } catch (error) {
        console.log(error);
      }
    };
    if (renderOpener) checkRenderOpener();
  }, [renderOpener]);

  const onClick = () => {
    setShow(true);
  };

  return (
    <>
      <ModalForm
        isVisible={show}
        setIsVisible={setShow}
        onSubmit={submitService}
        title={heading}
        fields={fields}
        submitButton={submitButton}
        submitButtonText={submitButtonText}
        defaultValues={defaultValues}
        refreshEvent={refreshEvent}
        appendPayload={{
          ...appendPayload,
          ...getUrlSearchParams(searchParams),
        }}
        successMessage={successMessage}
        errorMessage={errorMessage}
        size={size}
      />
      {shouldRenderOpener && (
        <p className={`${openerClassName} mb-0`} onClick={onClick}>
          {openerText}
        </p>
      )}
    </>
  );
}

export default ModalFormOpener;
