import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
// import logo from "../../assets/img/icon.jpeg";
import logoTwo from "../../assets/img/logo.png";
// import avatar from "../../assets/img/avatars/avatar.jpg";

import SignIn from "../../components/auth/SignIn";

const SignInPage = () => (
  <React.Fragment>
    <Helmet title="Sign In" />
    <div className="text-center mt-4">
      <h2>Welcome back.</h2>
      <p className="lead">Sign in to your account to continue</p>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <div className="text-center">
            <div
              style={{
                height: "132px",
                width: "132px",
                overflow: "hidden",
                background: "#F1F1F5",
              }}
              className="image-wrapper rounded-circle m-auto d-flex justify-content-center align-items-center"
            >
              <img
                src={logoTwo}
                alt="Delta LMS Logo"
                className="img-fluid "
                width="132"
                height="132"
              />
            </div>
          </div>
          <SignIn />
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default SignInPage;
