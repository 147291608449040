import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";

const QuestionInput = ({ meta, formInstance, defaultValue, isInvalid }) => {
  const {
    key,
    addButtonText = "+ Add New",
    addButtonClassName,
    labelPrefix,
    listItemClassName,
    textDisabled = false,
  } = meta;
  const { setValue, setError, clearErrors, register, control } = formInstance;

  const [fieldsData, setFieldsData] = useState(defaultValue || []);

  const append = useCallback((data = { name: "", correct: false }) => {
    setFieldsData((prev) => [...prev, data]);
  }, []);

  const remove = useCallback((index) => {
    setFieldsData((prev) => prev.filter((d, idx) => idx !== index));
  }, []);

  function moveElement(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  const checkError = () => {
    if (formInstance.getValues("no_right_answer")) return true;
    return !!fieldsData
      .map((data) => data.correct)
      .reduce((acc, cur) => (acc |= cur), false)
      ? true
      : "Must select at least one correct solution.";
  };

  useEffect(() => {
    setValue(key, fieldsData);
  }, [clearErrors, fieldsData, key, setError, setValue]);

  const handleDragEnd = ({ source, destination }) => {
    const newValues = moveElement(fieldsData, source.index, destination.index);
    setFieldsData(newValues);
    setValue(key, newValues);
  };

  return (
    <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
      <Form.Control
        {...register(key, { validate: checkError })}
        className="d-none"
      />
      {fieldsData.map((data, index) => {
        const fieldNamePrefix = `${key}[${index}]`;
        return (
          <div
            key={fieldNamePrefix}
            className="d-flex justify-content-between align-items-center"
          >
            <span className="w-100">
              <div className={`list-input-row ${listItemClassName || ""}`}>
                {!!labelPrefix && (
                  <p className="mt-2 mb-1">{`${labelPrefix} ${index + 1}`}</p>
                )}
                <div className="col-9">
                  {/* <Form.Control
                              type="text"
                              className="col-4"
                              disabled={textDisabled}
                              placeholder={"Option Name"}
                              value={data?.name}
                              onChange={(e) => {
                                const name = e.target.value;
                                setFieldsData((prev) =>
                                  prev.map((d, idx) =>
                                    idx === index ? { ...d, name } : { ...d }
                                  )
                                );
                              }}
                            /> */}
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                    // onInit={(evt, editor) => (ref.current = editor)}
                    value={data?.name}
                    onEditorChange={(e) => {
                      const name = e;
                      setFieldsData((prev) =>
                        prev.map((d, idx) =>
                          idx === index ? { ...d, name } : { ...d }
                        )
                      );
                    }}
                    init={{
                      height: 240,
                      menubar: false,
                      branding: false,
                      plugins:
                        "advlist lists autolink link image charmap print preview anchor",

                      toolbar:
                        "undo redo | formatselect | fontselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
                <Form.Check
                  type="checkbox"
                  label="Correct"
                  name="correct"
                  className="col-3"
                  isInvalid={isInvalid}
                  checked={data?.correct}
                  onChange={(e) => {
                    const correct = e.target.checked;
                    setFieldsData((prev) =>
                      prev.map((d, idx) =>
                        idx === index ? { ...d, correct } : { ...d }
                      )
                    );
                  }}
                />
              </div>
            </span>
            <span
              onClick={() => {
                remove(index);
              }}
              style={{
                display: "inline-block",
                width: 8,
                padding: 0,
                margin: 0,
                marginBottom: 10,
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
        );
      })}
      {!textDisabled && (
        <span
          type="button"
          onClick={() => append()}
          className={addButtonClassName || "link-primary"}
        >
          {addButtonText}
        </span>
      )}
    </DragDropContext>
  );
};

export default QuestionInput;
