import React, { useEffect } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import logo from "../../assets/img/logo-white.png";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen, hideFromDisplay, setHideFromDisplay } = useSidebar();
  const router = useLocation();
  const { user } = useAuth();
  const pathName = router.pathname;

  useEffect(() => {
    hideFromDisplay && setHideFromDisplay(false);
  }, [pathName]);

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand d-flex" href="/">
            <div className="d-flex justify-content-center align-items-center">
              <img width="45px" className="me-2" src={logo} alt="Logo" />{" "}
            </div>
            <span className="align-middle me-3">
              {/* Delta Iut Admission Guidelines */}
              Delta Admin
            </span>
          </a>

          <SidebarNav
            items={items?.filter((item) =>
              item.visible_to.includes(user?.role)
            )}
          />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
