import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ListView from "../../../components/listView/ListView";
import { orderFilterMeta } from "../../../configs/meta/filterMeta/orderFilterMeta";
import orderListViewMeta from "../../../configs/meta/listViewMeta/orderListViewMeta";

const Order = () => (
  <React.Fragment>
    <Helmet title="Order Page" />
    <Container fluid className="p-0">
      <ListView
        filters={orderFilterMeta}
        meta={orderListViewMeta()}
        title="Order List"
      />
    </Container>
  </React.Fragment>
);

export default Order;
