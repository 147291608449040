import inputTypes from "constants/form/inputTypes";

const enrolmentEditFields = [
  {
    className: "col-3 min-width-20",
    inputType: inputTypes.SELECT,
    key: "status",
    labelText: "Status",
    options: [
      {
        label: "Accepted",
        value: "accepted",
      },
      {
        label: "Rejected",
        value: "rejected",
      },
    ],
  },
];

export default enrolmentEditFields;
