import {
  createNotice,
  deleteNotice,
  getNotice,
  updateNotice,
} from "services/api/queries/notice";
import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import noticeFields from "../formFields/noticeFields";

const noticeListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Course",
        accessor: "course.name",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateNotice}
              fields={noticeFields}
              heading={"Edit a Notice!"}
              openerText="Edit"
              parentId="modal_span"
              defaultValues={{
                ...defaultValues,
                course: defaultValues.course.id,
                title: defaultValues.title,
                description: defaultValues.description,
                status: defaultValues.status,
              }}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Notice updated!"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteNotice}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this Notice?"
              heading="Delete Notice"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createNotice}
        fields={noticeFields}
        heading={"Create a Notice!"}
        openerText="+ Add Notice"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Created Notice!"
        openerClassName="btn btn-primary"
      />,
    ],
    queryService: getNotice,
    addModal: {
      fields: noticeFields,
      title: "Add a Notice",
      onSubmit: createNotice,
    },
    modelName: "notice",
    addButtonText: "+ Add Notice",
  };
};

export default noticeListViewMeta;
