import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import circularListViewMeta from "../../../configs/meta/listViewMeta/circularListViewMeta";

const Circular = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView meta={circularListViewMeta()} title="Circular List" />
    </Container>
  </React.Fragment>
);

export default Circular;
