import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import faqListViewMeta from "../../../configs/meta/listViewMeta/faqListViewMeta";
import { faqFilterMeta } from "../../../configs/meta/filterMeta/faqFilterMeta";

const Faq = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView
        filters={faqFilterMeta}
        meta={faqListViewMeta()}
        title="Faq List"
      />
    </Container>
  </React.Fragment>
);

export default Faq;
