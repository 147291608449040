import {
  UNANSWERED_QUESTIONS,
  BLOG,
  BLOG_CATEGORY,
  CATEGORY_PAGE,
  CIRCULAR_PAGE,
  COUPON_PAGE,
  COUPON_USAGE_PAGE,
  COURSE_PAGE,
  DASHBOARD,
  ENROLMENT_PAGE,
  FREE_EXAM,
  FREE_EXAM_PARTICIPANTS,
  INSTRUCTOR_PAGE,
  JOIN_DISCUSSIONS,
  NOTICE_PAGE,
  REGULAR_ENROLMENT_PAGE,
  REVIEW_PAGE,
  SITE_INFO_PAGE,
  SUB_CATEGORY_PAGE,
  TEMPLATE_PAGE,
  USER_MANAGEMENT,
  WRITTEN_EVALUATION_PAGE,
  MANAGE_SALES,
  STUDENTS_PAGE,
  SUBSCRIPTION,
  STUDENTS_REVIEW,
  BOOK_REVIEW,
  BOOK_ORDER,
  FAQ_TYPE,
  FAQ,
} from "constants/routes";
import {
  faBook,
  faBriefcase,
  faBullhorn,
  faChalkboardTeacher,
  faChartBar,
  faClipboardCheck,
  faClipboardList,
  faCogs,
  faFileSignature,
  faGraduationCap,
  faHandHoldingUsd,
  faMailBulk,
  faScroll,
  faSearchDollar,
  faUserFriends,
  faUserGraduate,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";

const pagesSection = [
  {
    href: DASHBOARD,
    icon: faChartBar,
    title: "Dashboard",
  },
];

const courseSection = [
  {
    href: CATEGORY_PAGE,

    title: "Category",
  },
  {
    href: SUB_CATEGORY_PAGE,

    title: "Sub-Category",
  },
  {
    href: INSTRUCTOR_PAGE,

    title: "Instructors",
  },
  {
    href: COURSE_PAGE,

    title: "Courses",
  },
];
const noticeBoardSection = [
  {
    href: NOTICE_PAGE,

    title: "Notices",
  },
];
const templateSection = [
  {
    href: TEMPLATE_PAGE,

    title: "Question Template",
  },
];

const enrolmentSection = [
  {
    href: REGULAR_ENROLMENT_PAGE,

    title: "Online Enrolment",
  },
  {
    href: ENROLMENT_PAGE,

    title: "Offline Enrolment",
  },
];

const couponsSection = [
  {
    href: COUPON_PAGE,

    title: "Coupons",
  },
  {
    href: COUPON_USAGE_PAGE,

    title: "Coupon Usage",
  },
];

const writtenEvaluationSection = [
  {
    href: WRITTEN_EVALUATION_PAGE,

    title: "Written Answers",
  },
];

const configurationSection = [
  {
    href: SITE_INFO_PAGE,

    title: "Site Info",
  },
  {
    href: FAQ_TYPE,

    title: "Faq Type",
  },
  {
    href: FAQ,

    title: "Faq",
  },
];

const blogSection = [
  {
    href: BLOG,

    title: "Blogs",
  },
  {
    href: BLOG_CATEGORY,

    title: "Blog Category",
  },
];

const userManagementSection = [
  {
    href: USER_MANAGEMENT,

    title: "Users",
  },
];

const freeExamSection = [
  {
    href: FREE_EXAM,

    title: "Model Test",
  },
  {
    href: FREE_EXAM_PARTICIPANTS,

    title: "Model Test Participants",
  },
];

const reviewSection = [
  {
    href: REVIEW_PAGE,

    title: "Reviews",
  },
  {
    href: STUDENTS_REVIEW,

    title: "Student Reviews",
  },
  {
    href: BOOK_REVIEW,

    title: "Book Reviews",
  },
];

const answerManagement = [
  { href: JOIN_DISCUSSIONS, title: "Join Discussion" },
  { href: UNANSWERED_QUESTIONS, title: "Unanswered Questions" },
];

const circularSection = [
  {
    href: CIRCULAR_PAGE,
    title: "Circulars",
  },
];

const salesSection = [
  {
    href: MANAGE_SALES,
    title: "Sales",
  },
];

const studentSection = [
  {
    href: STUDENTS_PAGE,
    title: "Students",
  },
];

const subscriptionSection = [
  {
    href: SUBSCRIPTION,
    title: "Subscription",
  },
];

const orderSection = [
  {
    href: BOOK_ORDER,

    title: "Book Orders",
  },
];

const navItems = [
  {
    key: "pages",
    visible_to: ["admin", "content_creator"],
    pages: pagesSection,
  },
  {
    key: "Manage Course",
    visible_to: ["admin", "content_creator"],
    pages: [
      {
        href: COURSE_PAGE,
        icon: faGraduationCap,
        title: "Manage Course",
        children: courseSection,
      },
    ],
  },
  {
    key: "Manage Reviews",
    visible_to: ["admin", "content_creator"],
    pages: [
      {
        href: COURSE_PAGE,
        icon: faClipboardCheck,
        title: "Manage Reviews",
        children: reviewSection,
      },
    ],
  },
  {
    key: "Manage Notice Board",
    visible_to: ["admin", "content_creator"],
    pages: [
      {
        href: COURSE_PAGE,
        icon: faBullhorn,
        title: "Manage Notice Board",
        children: noticeBoardSection,
      },
    ],
  },
  {
    key: "Manage Question",
    visible_to: ["admin", "content_creator"],
    pages: [
      {
        href: TEMPLATE_PAGE,
        icon: faScroll,
        title: "Manage Question",
        children: templateSection,
      },
    ],
  },
  {
    key: "Manage Enrolment",
    visible_to: ["admin"],
    pages: [
      {
        href: ENROLMENT_PAGE,
        icon: faUserGraduate,
        title: "Manage Enrolment",
        children: enrolmentSection,
      },
    ],
  },
  {
    key: "Manage Coupon",
    visible_to: ["admin"],
    pages: [
      {
        href: COUPON_PAGE,
        icon: faHandHoldingUsd,
        title: "Manage Coupons",
        children: couponsSection,
      },
    ],
  },
  {
    key: "Manage Exam Answer",
    visible_to: ["admin"],
    pages: [
      {
        href: WRITTEN_EVALUATION_PAGE,
        icon: faBook,
        title: "Manage Exam Answer",
        children: writtenEvaluationSection,
      },
    ],
  },
  {
    key: "Manage Book Orders",
    visible_to: ["admin"],
    pages: [
      {
        href: BOOK_ORDER,
        icon: faBook,
        title: "Manage Book Orders",
        children: orderSection,
      },
    ],
  },
  {
    key: "Manage Settings",
    visible_to: ["admin"],
    pages: [
      {
        // href: CHARACTERISTIC_PAGE,
        icon: faCogs,
        title: "Manage Settings",
        children: configurationSection,
      },
    ],
  },
  {
    key: "user-management",
    visible_to: ["admin"],
    pages: [
      {
        icon: faUserFriends,
        title: "Manage User",
        children: userManagementSection,
      },
    ],
  },
  {
    key: "Blog",
    visible_to: ["admin", "content_creator"],
    pages: [
      {
        icon: faFileSignature,
        title: "Manage Blog",
        children: blogSection,
      },
    ],
  },
  {
    key: "answer-questions",
    visible_to: ["admin", "content_creator"],
    pages: [
      {
        icon: faChalkboardTeacher,
        title: "Manage Forum",
        children: answerManagement,
      },
    ],
  },
  {
    key: "free-exam",
    visible_to: ["admin"],
    pages: [
      {
        icon: faClipboardList,
        title: "Manage Model Test",
        children: freeExamSection,
      },
    ],
  },
  {
    key: "circular-management",
    visible_to: ["admin"],
    pages: [
      {
        title: "Manage Circular",
        children: circularSection,
        icon: faBriefcase,
      },
    ],
  },
  {
    key: "sales-management",
    visible_to: ["admin"],
    pages: [
      {
        title: "Manage Sales",
        children: salesSection,
        icon: faSearchDollar,
      },
    ],
  },
  {
    key: "students-management",
    visible_to: ["admin"],
    pages: [
      {
        title: "Manage Students",
        children: studentSection,
        icon: faUserTie,
      },
    ],
  },
  {
    key: "subscription-management",
    visible_to: ["admin"],
    pages: [
      {
        title: "Manage Subscription",
        children: subscriptionSection,
        icon: faMailBulk,
      },
    ],
  },
];

export default navItems;
