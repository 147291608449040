import { getSalesData } from "../../../services/api/queries/enrolment";
import moment from "moment";

const enrolmentTypeMap = {
  regular: "Online",
  manual: "Manual",
};

const enrolmentStatusMap = {
  pending: "Pending",
  accepted: "Enrolled",
  rejected: "Rejected",
};

const salesManagementListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Invoice",
        accessor: "invoice_no",
        Cell: ({ row }) => (
          <a href={`/pages/view-invoice/${row.original.id}`}>
            {row.original.invoice_no}
          </a>
        ),
      },
      {
        Header: "Course Name",
        accessor: "course.name",
      },
      {
        Header: "Username",
        accessor: "contact_number",
      },
      {
        Header: "Enrolment Date",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY hh:mm a"),
      },
      {
        Header: "Expiration Date",
        accessor: "expiration_date",
        Cell: ({ value }) => (
          <p
            className={
              moment(value).isBefore(moment.now()) ? "text-danger" : ""
            }
          >
            {moment(value).format("MMM DD, YYYY")}
          </p>
        ),
      },
      {
        Header: "Sales Channel",
        accessor: "e_type",
        Cell: ({ value }) => enrolmentTypeMap[value] || value,
      },
      {
        Header: "Paid Amount",
        accessor: "paid_amount",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => enrolmentStatusMap[value],
      },
    ],
    actions: [],
    queryService: getSalesData,
  };
};

export default salesManagementListViewMeta;
