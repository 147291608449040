import inputTypes from "constants/form/inputTypes";
import { getCourseForDropdown } from "../../../services/api/queries/course";
import { getStudentsForDrpodown } from "../../../services/api/queries/enrolment";

const enrolmentFields = [
  {
    className: "mb-3",
    inputType: inputTypes.CREATABLE_SELECT,
    key: "contact_number",
    labelText: "Phone Number",
    placeHolder: "Enter Phone Number",
    rules: { required: true },
    lookupQuery: getStudentsForDrpodown,
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "course",
    labelText: "Course",
    placeHolder: "Select Course",
    rules: { required: true },
    lookupQuery: getCourseForDropdown,
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Name",
    placeHolder: "Enter Name",
    rules: { required: true },
    dependencies: ["contact_number"],
    onDependencyValueChange: ({ contact_number }, key, { setValue }) => {
      const contact = contact_number.split("__");
      setValue("name", contact?.length > 1 ? contact[2] : "");
    },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "payment_from",
    labelText: "Payment From",
    placeHolder: "Enter Payment From Number",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "payment_to",
    labelText: "Payment To",
    placeHolder: "Enter Payment To Number",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.NUMBER,
    key: "paid_amount",
    labelText: "Paid Amount",
    placeHolder: "Enter Payment Amount",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "note",
    labelText: "Note",
    placeHolder: "Enter Note",
  },
];

export default enrolmentFields;
