import {
  createSiteInfo,
  getSiteInfo,
  hasSiteInfo,
  updateSiteInfo,
} from "services/api/queries/site-info";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import siteInfoFields from "../formFields/siteInfoFields";

const siteInfoListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Banner Title",
        accessor: "banner_title",
      },
      {
        Header: "Banner Title (Bangla)",
        accessor: "banner_title_bn",
      },
      {
        Header: "Banner Overview",
        accessor: "banner_overview",
      },
      {
        Header: "Banner Overview (Bangla)",
        accessor: "banner_overview_bn",
      },
      {
        Header: "Achievement Title",
        accessor: "achievement_title",
      },
      {
        Header: "Achievement Title (Bangla)",
        accessor: "achievement_title_bn",
      },
      {
        Header: "Achievement Overview",
        accessor: "achievement_overview",
      },
      {
        Header: "Achievement Overview (Bangla)",
        accessor: "achievement_overview_bn",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateSiteInfo}
              fields={siteInfoFields}
              heading={"Edit a Site Info!"}
              openerText="Edit"
              defaultValues={{
                ...defaultValues,
                ...(defaultValues?.social_links || {}),
              }}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Site Info updated!"
              openerClassName="w-100"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createSiteInfo}
        fields={siteInfoFields}
        heading={"Create a Characteristic!"}
        openerText="+ Add Site Info"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        renderOpener={hasSiteInfo}
        successMessage="Site Info Created!"
      />,
    ],
    queryService: getSiteInfo,
    addButtonText: "+ Add Site Info",
  };
};

export default siteInfoListViewMeta;
