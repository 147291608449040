import moment from "moment";
import { getCouponUsages } from "../../../services/api/queries/coupon";

const couponUsageListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Code Name",
        accessor: "coupon.code_name",
      },
      {
        Header: "Student",
        accessor: "user.username",
      },
      {
        Header: "Enrolled Course",
        accessor: "course.name",
      },
      {
        Header: "Discount Type",
        accessor: "coupon.discount_type",
      },
      {
        Header: "Discount Value",
        accessor: "coupon.discount_value",
      },
      {
        Header: "Price after Discount",
        accessor: "enrollment.paid_amount",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY"),
      },
    ],
    actions: [],
    queryService: getCouponUsages,
  };
};

export default couponUsageListViewMeta;
