import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createUser,
  getUsers,
  updateUser,
} from "../../../services/api/queries/users";
import userFields from "../formFields/userFields";

const userManagementListViewMeta = () => {
  return {
    columns: [
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "User Type",
        accessor: "role",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateUser}
              fields={userFields.filter((field) => field.key !== "password")}
              heading={"Edit a User!"}
              openerText="Edit"
              parentId="modal_span"
              defaultValues={{
                ...defaultValues,
                reviewer_image: [defaultValues.reviewer_image],
                course: defaultValues.course?.id
                  ? defaultValues.course?.id
                  : defaultValues.course,
              }}
              refreshEvent="refresh_table"
              size="xl"
              doNotReset={true}
              successMessage="User updated!"
              openerClassName="w-100"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createUser}
        fields={userFields}
        heading={"Create a User!"}
        openerText="+ Add User"
        parentId="modal_span"
        refreshEvent="refresh_table"
        size="xl"
        doNotReset={true}
        successMessage="User Created!"
        openerClassName="btn btn-primary"
      />,
    ],
    queryService: getUsers,
  };
};

export default userManagementListViewMeta;
