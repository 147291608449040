import inputTypes from "constants/form/inputTypes";

const classNoteFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Note Title",
    placeHolder: "Enter Note Title",
    rules: { required: true },
  },
  {
    className: "col-6 pb-3",
    inputType: inputTypes.FILE,
    key: "document",
    labelText: "Document:",
    multiple: false,
  },
];

export default classNoteFields;
