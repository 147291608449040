import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createTemplate = async (data) => {
  return await adminApi({
    endpoint: endpoints.TEMPLATE,
    method: "post",
    data: data,
  });
};

export const updateTemplate = async (data) => {
  const payload = {
    ...data,
    name: data.name,
    is_sectionize: data.is_sectionize,
  };
  return await adminApi({
    endpoint: `${endpoints.TEMPLATE}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getTemplate = async (data) => {
  return await adminApi({
    endpoint: endpoints.TEMPLATE,
    method: "get",
    params: data,
  });
};

export const getTemplateForDropdown = async (data) => {
  const res = await adminApi({
    endpoint: endpoints.TEMPLATE,
    method: "get",
    params: { page_size: 0 },
  });

  return res.data?.map((template) => ({
    value: template.id,
    label: template.name,
  }));
};

export const deleteTemplate = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.TEMPLATE}${data.id}/`,
    method: "delete",
  });
};

export const duplicateTemplate = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.TEMPLATE}${data.id}/duplicate/`,
    method: "post",
    data: data,
  });
};

export const getTemplateQuestions = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.TEMPLATE_QUESTIONS}${data.id}/`,
    method: "get",
    params: data,
  });
};
