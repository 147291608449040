import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const getDashboardData = async (data) => {
  return await adminApi({
    endpoint: endpoints.DASHBOARD,
    method: "get",
    params: data,
  });
};

export const getDashboardEnrollmentData = async (data) => {
  return await adminApi({
    endpoint: endpoints.DASHBOARD_ENROLLMENTS,
    method: "get",
    params: data,
  });
};
