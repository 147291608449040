import inputTypes from "constants/form/inputTypes";

const loginFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "username",
    labelText: "Your Username",
    placeHolder: "Your Username",
    rules: { required: true },
    // append: () => <FontAwesomeIcon icon={faAdobe} />,
  },
  {
    className: "mb-3",
    inputType: inputTypes.PASSWORD,
    key: "password",
    labelText: "Your Password",
    placeHolder: "Your Password",
    rules: { required: true },
    // append: () => <FontAwesomeIcon icon={faAdobe} />,
  },
];

export default loginFields;
