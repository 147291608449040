import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import LinkOpener from "../../../components/actions/LinkOpener";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createSection,
  deleteSection,
  getSections,
  updateSection,
} from "../../../services/api/queries/section";
import sectionFields from "../formFields/sectionFields";

const sectionListViewMeta = ({ id }) => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Section",
        accessor: "name",
      },
      {
        Header: "Marks",
        accessor: "total_marks",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateSection}
              fields={sectionFields}
              heading={"Edit a Section!"}
              openerText="Edit"
              defaultValues={defaultValues}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Section updated!"
            />
          ),
          (defaultValues) => (
            <LinkOpener
              key="questions"
              openerText="Question List"
              navigateTo={`/pages/quiz-management/question?template_id=${defaultValues.template}&section_id=${defaultValues.id}`}
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteSection}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this section?"
              heading="Delete Section"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createSection}
        fields={sectionFields}
        heading={"Create a Section!"}
        openerText="+ Add Section"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Section Created!"
      />,
    ],
    queryService: () => getSections({ template: id }),
    modelName: "section",
    addButtonText: "+ Add Section",
  };
};

export default sectionListViewMeta;
