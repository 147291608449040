import React, { useCallback, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  getDateRangeSearchUrlParam,
  parseDateRangeSearchUrlParam,
} from "../../../utils/date-range";

const SelectInput = ({
  meta,
  defaultValue,
  formInstance,
  dependencyValues,
}) => {
  const { key, rules = {}, show = true } = meta;
  const { control } = formInstance;
  const [display, setDisplay] = useState(show);

  const toggleVisibilityOfSelect = useCallback((condition) => {
    if (condition) setDisplay(false);
    else setDisplay(true);
  }, []);

  useEffect(() => {
    if (!meta.dependencies?.length || !Object.keys(dependencyValues).length)
      return;
    meta?.onDependencyValueChange(dependencyValues, meta.key, {
      toggleVisibilityOfSelect,
    });
  }, [meta, dependencyValues, toggleVisibilityOfSelect]);

  return (
    display && (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={key}
        rules={rules}
        shouldUnregister
        render={({ field: { onChange, value, ref } }) => (
          <DateRangePicker
            onChange={(val) => {
              onChange(getDateRangeSearchUrlParam(val));
            }}
            value={parseDateRangeSearchUrlParam(value)}
          />
        )}
      />
    )
  );
};

export default SelectInput;
