import moment from "moment";
import {
  createCharacteristic,
  deleteCharacteristic,
  getCharacteristic,
  updateCharacteristic,
} from "services/api/queries/characteristic";
import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import characteristicFields from "../formFields/characteristicFields";

const characteristicListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Title (Bangla)",
        accessor: "title_bn",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY"),
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY"),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateCharacteristic}
              fields={characteristicFields}
              heading={"Edit a Characteristic!"}
              openerText="Edit"
              defaultValues={{
                ...defaultValues,
              }}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Characteristic updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteCharacteristic}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this category?"
              heading="Delete Characteristic"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createCharacteristic}
        fields={characteristicFields}
        heading={"Create a Characteristic!"}
        openerText="+ Add Characteristic"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Characteristic Created!"
      />,
    ],
    queryService: getCharacteristic,
    addButtonText: "+ Add Characteristic",
  };
};

export default characteristicListViewMeta;
