import {
  getWrittenQuestionForEvaluation,
  updateWrittenEvaluation,
} from "services/api/queries/written-evaluation";
import Markdown from "components/markdown/Markdown";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import writtenEvaluationFields from "../formFields/writtenEvaluationFields";

const writtenEvaluationListViewMeta = () => {
  return {
    columns: [
      {
        Header: "User",
        accessor: "user_name",
      },
      {
        Header: "Question",
        accessor: "question_name",
        Cell: ({ value }) => <Markdown text={value} />,
      },
      {
        Header: "Question Mark",
        accessor: "question_mark",
      },
      {
        Header: "Course Name",
        accessor: "course_name",
      },
      {
        Header: "Topic Name",
        accessor: "topic_name",
      },
      {
        Header: "Mark",
        accessor: "mark",
      },
      {
        Header: "Is Evaluated",
        accessor: "written_evaluation",
        Cell: ({ value }) => (value ? "✓" : "X"),
      },

      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={
                !defaultValues.written_evaluation
                  ? updateWrittenEvaluation
                  : null
              }
              fields={
                !defaultValues.written_evaluation
                  ? writtenEvaluationFields
                  : writtenEvaluationFields.map((field) =>
                      field.key === "mark"
                        ? { ...field, disabled: true }
                        : field
                    )
              }
              heading={"Evaluate a written question!"}
              openerText={
                !defaultValues.written_evaluation ? "Evaluate" : "View"
              }
              defaultValues={{ ...defaultValues }}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Evaluated!"
              openerClassName="w-100"
              submitButton={
                !defaultValues.written_evaluation
                  ? {
                      className: "btn btn-primary rounded-0",
                      label: "Submit",
                      isDisabled: false,
                    }
                  : {
                      className: "btn btn-primary rounded-0",
                      label: "Ok",
                      isDisabled: false,
                    }
              }
            />
          ),
        ],
      },
    ],
    actions: [],
    queryService: getWrittenQuestionForEvaluation,
  };
};

export default writtenEvaluationListViewMeta;
