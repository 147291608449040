import inputTypes from "constants/form/inputTypes";
import { getCourseForDropdown } from "../../../services/api/queries/course";

const couponFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "code_name",
    labelText: "Code Name",
    placeHolder: "Enter Code Name",
    rules: { required: true },
  },
  {
    className: "col-4",
    inputType: inputTypes.SELECT,
    key: "discount_type",
    labelText: "Discount Type",
    placeholder: "Discount Type",
    options: [
      {
        label: "Percentage",
        value: "percentage",
      },
      {
        label: "Flat",
        value: "flat",
      },
    ],
    rules: { required: true },
  },
  {
    className: "mb-3 col-4 d-inline-block",
    inputType: inputTypes.NUMBER,
    key: "discount_value",
    labelText: "Discount Value",
    placeHolder: "Enter Value",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "courses",
    placeHolder: "Select Courses",
    labelText: "Select Courses",
    isMulti: true,
    multiSelectAll: true,
    lookupQuery: getCourseForDropdown,
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.DATE,
    key: "start_date",
    placeHolder: "Select Start Date",
    labelText: "Start Date",
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.DATE,
    key: "end_date",
    placeHolder: "Select End Date",
    labelText: "End Date",
  },
];

export default couponFields;
