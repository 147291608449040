import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const createSubCategory = async (data) => {
  return await adminApi({
    endpoint: endpoints.SUB_CATEGORY,
    method: "post",
    data: data,
  });
};

export const getSubCategory = async (data) => {
  return await adminApi({
    endpoint: endpoints.SUB_CATEGORY,
    method: "get",
    params: data,
  });
};

export const updateSubCategory = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.SUB_CATEGORY}${data.id}/`,
    method: "patch",
    data: data,
  });
};

export const deleteSubCategory = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.SUB_CATEGORY}${data.id}/`,
    method: "delete",
  });
};

export const getSubCategoryForDropdown = async (data) => {
  const res = await adminApi({
    endpoint: endpoints.SUB_CATEGORY,
    method: "get",
    params: { page_size: 0, ...data },
  });

  const ret = res.data?.map((subcat) => ({
    value: subcat.id,
    label: subcat.name,
  }));
  return ret || [];
};
