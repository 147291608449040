import inputTypes from "../../../constants/form/inputTypes";

export const freeExamParticipantsFilterViewMeta = [
  {
    className: "col-4",
    inputType: inputTypes.TEXT,
    key: "search",
    labelText: "Phone Number",
  },
  {
    className: "col-4 mt-1",
    inputType: inputTypes.DATE,
    key: "created_at_after",
    labelText: "From Date",
    isID: true,
  },
  {
    className: "col-4 mt-1",
    inputType: inputTypes.DATE,
    key: "created_at_before",
    labelText: "To Date",
    isID: true,
  },
];
