import inputTypes from "constants/form/inputTypes";

const faqTypeFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Name",
    placeHolder: "Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name_bn",
    labelText: "Name Bangla",
    placeHolder: "Name Bangla",
    rules: { required: true },
  },
];

export default faqTypeFields;
