import React from "react";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import ListView from "../../../components/listView/ListView";
import sectionListViewMeta from "../../../configs/meta/listViewMeta/sectionListViewMeta";

const SectionList = () => {
  const [searchParams] = useSearchParams();

  const getTitle = (data) => {
    return data.name;
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <ListView
          meta={sectionListViewMeta({ id: searchParams.get("template_id") })}
          title="Section List"
        />
      </Container>
    </React.Fragment>
  );
};
export default SectionList;
