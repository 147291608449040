import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Container, Dropdown, Table as BootstrapTable } from "react-bootstrap";
import { usePagination, useTable } from "react-table";
import { orderTable } from "../../services/api/queries/ordering";
import { getUrlSearchParams, moveElement } from "../../utils/urls";
import Loader from "../Loader";
import Pagination from "./pagination/Pagination";

const Table = ({
  columns,
  actions,
  performQuery,
  title,
  params,
  setParams,
  tableData,
  setTableData,
  isLoading,
  modelName,
  setIsLoading,
  pagination = true,
}) => {
  const [currentPage, setCurrentPage] = useState(
    getUrlSearchParams(params).current || 1
  );
  const [pageLimit, setPageLimit] = useState(
    getUrlSearchParams(params).page_size || 10
  );
  const [goToPageInput, setGoToPageInput] = useState(1);

  const columnData = useMemo(
    () => columns?.filter?.((data) => data.accessor !== "actions"),
    [columns]
  );
  const rowActions = useMemo(
    () => columns?.filter?.((data) => data.accessor === "actions"),
    [columns]
  )?.[0]?.options;

  useEffect(() => {
    performQuery();
  }, [performQuery, params]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns: columnData,
        data: tableData?.rowData || [],
        initialState: { data: [] },
        manualPagination: true,
        pageCount: tableData?.total || 10,
      },
      usePagination,
      (hooks) => {
        hooks.visibleColumns.push((columns) =>
          rowActions
            ? [
                ...columns,
                {
                  id: "Actions",
                  Header: "Action",
                  Cell: ({ row }) => <span key={row.accessor}></span>,
                },
              ]
            : [...columns]
        );
      }
    );
  const handlePaginationChange = (current, pageSize = pageLimit) => {
    setPageLimit(pageSize);
    setCurrentPage(current);
    setGoToPageInput(current);
    setIsLoading(true);

    const newParams = {
      ...getUrlSearchParams(params),
      page_size: pageSize,
      page: current,
    };
    setParams(newParams);
  };

  const handleDragEnd = async ({ source, destination }) => {
    // setIsLoading(true);
    const newValues = moveElement(
      [...(tableData?.rowData || [])],
      source.index,
      destination.index
    );

    setTableData((prev) => ({ ...prev, rowData: newValues }));
    const data = newValues.map((val, i) => ({ id: val.id, ordering: i + 1 }));

    const res = orderTable({ data, model_name: modelName })
      .then((info) => {
        const resp = info;
        if (!resp.success) throw resp;
        // performQuery();
        return info;
      })
      .catch((err) => {
        performQuery();
      });
    return res;
  };

  return (
    <React.Fragment>
      <Loader isLoading={isLoading}>
        <Container fluid className="p-0">
          <div className="d-flex justify-content-between mt-3 mb-3">
            <h2>{title}</h2>
            {actions.map((action) => action)}
          </div>
          <div id="modal_span"></div>
          <BootstrapTable striped bordered {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
              <Droppable droppableId="table-droppable">
                {(provided) => (
                  <tbody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    key="droppable-topic"
                    {...getTableBodyProps()}
                  >
                    {page.map((row, i) => (
                      <Draggable
                        draggableId={i?.toString()}
                        key={i?.toString()}
                        index={i}
                      >
                        {(provided) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps()}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    key={cell.column?.id}
                                  >
                                    {cell.render("Cell")}
                                    {cell.column?.id === "Actions" && (
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          className="makedot"
                                          variant="light"
                                        >
                                          {/* Actions */}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {rowActions.map(
                                            (tableAction, idx) => (
                                              <div
                                                className="dropdown-item w-100 cursor-pointer"
                                                key={idx}
                                              >
                                                {tableAction(row.original)}
                                              </div>
                                            )
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                    {cell.column?.Header === "Sort" && (
                                      <span
                                        key="bars"
                                        {...provided.dragHandleProps}
                                      >
                                        <FontAwesomeIcon
                                          icon={faBars}
                                          className="me-3 mt-2"
                                          size="lg"
                                        />
                                      </span>
                                    )}
                                    {cell.column?.Header === "Serial" && (
                                      <p>
                                        {(currentPage - 1) * 10 +
                                          cell.row.index +
                                          1}
                                      </p>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </BootstrapTable>

          {pagination && (
            <Pagination
              current={currentPage}
              setCurrent={setCurrentPage}
              total={tableData.total}
              onChange={handlePaginationChange}
              pageSize={pageLimit}
              goToPageInput={goToPageInput}
              setGoToPageInput={setGoToPageInput}
            />
          )}
        </Container>
      </Loader>
    </React.Fragment>
  );
};

export default Table;
