import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { changePassword } from "../../services/api/queries/users";
import NotyfContext from "../../contexts/NotyfContext";
import { DASHBOARD } from "../../constants/routes";

function ChangePassword() {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const password = useRef({});

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
  });

  password.current = watch("new_password", "");

  const onSubmit = async (data) => {
    try {
      const res = await changePassword(data);
      if (!res.success) throw res;
      const message = res.data.Message;
      notyf.open({
        type: "success",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
      navigate(DASHBOARD);
    } catch (error) {
      const message = Array.isArray(error.error) ? error.error[0] : error.error;
      notyf.open({
        type: "danger",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Label className="mt-3">Old Password</Form.Label>
      <Form.Control
        type="password"
        placeholder="Enter your old password"
        {...register("old_password", { required: "Password is required!" })}
      />
      {errors.password && (
        <Form.Text className="text-danger">{errors.password.message}</Form.Text>
      )}

      <Form.Label className="mt-3">New Password</Form.Label>
      <Form.Control
        type="password"
        placeholder="Enter your new password"
        {...register("new_password", { required: "Password is required!" })}
      />
      {errors.password && (
        <Form.Text className="text-danger">{errors.password.message}</Form.Text>
      )}
      <Form.Label className="mt-3">Confirm Password</Form.Label>
      <Form.Control
        type="password"
        placeholder="Enter your email"
        {...register("password2", {
          validate: (value) =>
            value === password.current || "The passwords do not match",
        })}
      />
      {errors.password2 && (
        <Form.Text className="text-danger">
          {errors.password2.message}
        </Form.Text>
      )}
      <br />
      <Button type="submit" className="mt-3 display-block">
        Submit
      </Button>
    </Form>
  );
}

export default ChangePassword;
