import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import blogCategoryListViewMeta from "../../../configs/meta/listViewMeta/blogCategoryListViewMeta";
import { categoryFilterMeta } from "../../../configs/meta/filterMeta/categoryFilterMeta";

const BlogCategory = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView
        filters={categoryFilterMeta}
        meta={blogCategoryListViewMeta()}
        title="Blog Category List"
      />
    </Container>
  </React.Fragment>
);

export default BlogCategory;
