import React from "react";
import { Form } from "react-bootstrap";

function DateTimeInput({ meta, formInstance, isInvalid }) {
  const { key, placeHolder, rules = {} } = meta;
  const { register } = formInstance;
  return (
    <Form.Control
      type="datetime-local"
      placeholder={placeHolder || ""}
      isInvalid={isInvalid}
      {...register(key, { ...rules })}
    />
  );
}

export default DateTimeInput;
