/* eslint-disable prettier/prettier */
import React from "react";
import { Form } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const ExamQuestion = ({ question }) => {
  const renderQuestionMarker = (optionIndex) => {
    let text;
    // eslint-disable-next-line default-case
    switch (optionIndex) {
      case 0:
        text = "A";
        break;
      case 1:
        text = "B";
        break;
      case 2:
        text = "C";
        break;
      case 3:
        text = "D";
        break;
      case 4:
        text = "E";
      // eslint-disable-next-line no-fallthrough
      case 5:
        text = "F";
        break;
    }

    return text;
  };

  return (
    <div>
      <div className="question">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {question.name}
        </ReactMarkdown>
      </div>
      {question?.image ? (
        <div className="image-container mx-auto text-center my-2 py-4 ">
          <img src={question.image} alt="" />
        </div>
      ) : null}
      <div className="options">
        {question.q_type === "written" ? (
          <Form.Control
            rows={4}
            as="textarea"
            class_name="col-12"
            label={"Type your answer here"}
          />
        ) : question.q_type === "single_choice" ? (
          question?.options?.map((option, index) => (
            <>
              <div className="option-item">
                <input
                  type="radio"
                  name={`${question.id}`}
                  placeholder={option.name}
                  className="form-check-input"
                  id={`${question.id}__${option?.name}`}
                  defaultValue="a"
                />
                <label
                  className="question-option d-flex"
                  htmlFor={`${question.id}__${option?.name}`}
                >
                  <span className="option-marker">
                    {renderQuestionMarker(index)}
                  </span>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {option.name}
                  </ReactMarkdown>
                </label>
                <br />
              </div>
            </>
          ))
        ) : (
          question?.options?.map((option, index) => (
            <>
              <div className="option-item">
                <input
                  type="checkbox"
                  name={`${question.id}`}
                  placeholder={option.name}
                  className="form-check-input"
                  id={`${question.id}__${option?.name}`}
                  defaultValue="a"
                />
                <label
                  className="question-option d-flex"
                  htmlFor={`${question.id}__${option?.name}`}
                >
                  <span className="option-marker">
                    {renderQuestionMarker(index)}
                  </span>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {option.name}
                  </ReactMarkdown>
                </label>
                <br />
              </div>
            </>
          ))
        )}
      </div>
    </div>
  );
};

export default ExamQuestion;
