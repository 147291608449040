import inputTypes from "constants/form/inputTypes";

const siteInfoFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "banner_title",
    labelText: "Banner Title",
    placeHolder: "Banner Title",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "banner_title_bn",
    labelText: "Banner Title (Bangla)",
    placeHolder: "Banner Title (Bangla)",
    rules: { required: true },
  },

  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "banner_overview",
    labelText: "Banner Overview",
    placeHolder: "Banner Overview",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "banner_overview_bn",
    labelText: "Banner Overview (Bangla)",
    placeHolder: "Banner Overview (Bangla)",
    rules: { required: true },
  },
  {
    className: "pb-3",
    inputType: inputTypes.FILE,
    key: "banner_image",
    labelText: "Banner Image:",
    multiple: false,
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "achievement_title",
    labelText: "Achievement Title",
    placeHolder: "Achievement Title",
    rules: { required: true },
  },

  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "achievement_title_bn",
    labelText: "Achievement Title (Bangla)",
    placeHolder: "Achievement Title (Bangla)",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "achievement_overview",
    labelText: "Achievement Overview",
    placeHolder: "Achievement Overview",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "achievement_overview_bn",
    labelText: "Achievement Overview (Bangla)",
    placeHolder: "Achievement Overview (Bangla)",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "facebook",
    labelText: "Facebook Link",
    placeHolder: "Facebook Link",
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "instagram",
    labelText: "Instagram Link",
    placeHolder: "Instagram Link",
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "youtube",
    labelText: "Youtube Link",
    placeHolder: "Youtube Link",
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "tiktok",
    labelText: "TikTok Link",
    placeHolder: "TikTok Link",
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "linkedin",
    labelText: "Linkedin Link",
    placeHolder: "Linkedin Link",
  },
];

export default siteInfoFields;
