import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createInstructor = async (data) => {
  const payload = {
    ...data,
    image: data.image[0],
  };
  return await adminApi({
    endpoint: endpoints.INSTRUCTOR,
    method: "post",
    data: payload,
  });
};

export const updateInstructor = async (data) => {
  const payload = {
    ...data,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: `${endpoints.INSTRUCTOR}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getInstructor = async (data) => {
  return await adminApi({
    endpoint: endpoints.INSTRUCTOR,
    method: "get",
    params: data,
  });
};

export const getInstructorForDropdown = async (data) => {
  const res = await adminApi({
    endpoint: endpoints.INSTRUCTOR,
    method: "get",
    params: { page_size: 0 },
  });

  return res.data?.map((instructor) => ({
    value: instructor.id,
    label: instructor.name,
  }));
};

export const deleteInstructor = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.INSTRUCTOR}${data.id}/`,
    method: "delete",
  });
};
