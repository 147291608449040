import { getSubscription } from "../../../services/api/queries/site-info";
import moment from "moment";

const subscriptionListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Subscribed at",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY hh:mm a"),
      },
    ],
    actions: [],
    queryService: getSubscription,
    addButtonText: "+ Add Insturctor",
  };
};

export default subscriptionListViewMeta;
