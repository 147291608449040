import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ListView from "../../../components/listView/ListView";
import { enrolmentFilterMeta } from "../../../configs/meta/filterMeta/enrolmentFilterMeta";
import regularEnrolmentListView from "../../../configs/meta/listViewMeta/regularEnrolmentListView";

const RegularEnrolment = () => (
  <React.Fragment>
    <Helmet title="Online Enrolment Page" />
    <Container fluid className="p-0">
      <ListView
        filters={enrolmentFilterMeta}
        meta={regularEnrolmentListView()}
        title="Regular Enrolment List"
      />
    </Container>
  </React.Fragment>
);

export default RegularEnrolment;
