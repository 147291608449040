import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import instructorListViewMeta from "../../../configs/meta/listViewMeta/instructorListViewMeta";

const Instructor = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView meta={instructorListViewMeta()} title="Instructor List" />
    </Container>
  </React.Fragment>
);

export default Instructor;
