import { getUrlSearchParams } from "utils/urls";
import { useSearchParams } from "react-router-dom";

function DownloadButton({ submitService }) {
  const [searchParams] = useSearchParams();
  const downloadReport = async () => {
    try {
      const res = await submitService({
        ...getUrlSearchParams(searchParams),
        report: true,
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "report.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {}
  };
  return (
    <div>
      <button
        className="btn btn-info btn-sm rounded-0"
        onClick={() => downloadReport()}
      >
        Download
      </button>
    </div>
  );
}

export default DownloadButton;
