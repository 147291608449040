import { getOrders, updateOrder } from "../../../services/api/queries/order";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import orderFields from "../formFields/orderFields";
import moment from "moment";

const orderListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Order No",
        accessor: "invoice_no",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Primary Phone",
        accessor: "primary_phone",
      },
      {
        Header: "Secondary Phone",
        accessor: "secondary_phone",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Order Date",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY hh:mm a"),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateOrder}
              fields={orderFields}
              heading={"Edit a Order!"}
              openerText="Edit"
              parentId="modal_span"
              defaultValues={defaultValues}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Edit Order!"
            />
          ),
        ],
      },
    ],
    actions: [],
    queryService: getOrders,
    addButtonText: "",
  };
};

export default orderListViewMeta;
