import { getStudents } from "../../../services/api/queries/student";

const studentListViewMeta = () => {
  return {
    columns: [
      {
        Header: "First Name",
        accessor: "first_name",
        headerClassName: "text-center",
        align: "center",
        Cell: ({ value }) =>
          value || (
            <p
              style={{
                textAlign: "center",
              }}
            >
              -
            </p>
          ),
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        align: "center",
        Cell: ({ value }) =>
          value || (
            <p
              style={{
                textAlign: "center",
              }}
            >
              -
            </p>
          ),
      },
      {
        Header: "Phone Number",
        accessor: "username",
      },
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }) => <img src={value} width="32px" alt="Student" />,
      },
    ],
    actions: [],
    queryService: getStudents,
  };
};

export default studentListViewMeta;
