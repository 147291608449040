import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createInstructor,
  deleteInstructor,
  getInstructor,
  updateInstructor,
} from "../../../services/api/queries/instructor";
import instructorFields from "../formFields/instructorFields";

const instructorListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Bangla Name",
        accessor: "name_bn",
      },
      {
        Header: "Designation",
        accessor: "designation",
      },
      {
        Header: "Designation (Bangla)",
        accessor: "designation_bn",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateInstructor}
              fields={instructorFields}
              heading={"Edit a Instructor!"}
              openerText="Edit"
              defaultValues={defaultValues}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Instructor updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteInstructor}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this instructor?"
              heading="Delete Instructor"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createInstructor}
        fields={instructorFields}
        heading={"Add an Instructor!"}
        openerText="+ Add Instructor"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Insturctor Added!"
      />,
    ],
    queryService: getInstructor,
    addButtonText: "+ Add Insturctor",
    modelName: "instructor",
  };
};

export default instructorListViewMeta;
