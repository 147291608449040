import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createCircular = async (data) => {
  const payload = {
    ...data,
    company_logo: data.company_logo[0],
  };
  return await adminApi({
    endpoint: endpoints.CIRCULAR,
    method: "post",
    data: payload,
  });
};

export const updateCircular = async (data) => {
  const payload = {
    ...data,
    courses: data.courses?.includes("select_all") ? null : data.courses,
    all_courses: data.courses?.includes("select_all"),
    company_logo: Array.isArray(data.company_logo)
      ? data.company_logo[0]
      : data.company_logo,
  };
  return await adminApi({
    endpoint: `${endpoints.CIRCULAR}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getCircular = async (data) => {
  return await adminApi({
    endpoint: endpoints.CIRCULAR,
    method: "get",
    params: data,
  });
};

export const getCircularUsages = async (data) => {
  return await adminApi({
    endpoint: endpoints.CIRCULAR_USAGES,
    method: "get",
    params: data,
  });
};

export const deleteCircular = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.CIRCULAR}${data.id}/`,
    method: "delete",
  });
};
