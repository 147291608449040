import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ListView from "../../../components/listView/ListView";
import reviewListViewMeta from "../../../configs/meta/listViewMeta/reviewListViewMeta";

const Review = () => (
  <React.Fragment>
    <Helmet title="Review Page" />
    <Container fluid className="p-0">
      <ListView meta={reviewListViewMeta()} title="Review List" />
    </Container>
  </React.Fragment>
);

export default Review;
