import React from "react";
import moment from "moment";

import { Card, Table } from "react-bootstrap";
import { toTitleCase } from "utils/func";

const Projects = ({ data }) => (
  <Card className="flex-fill w-100">
    <Card.Header>
      <Card.Title className="mb-0">Latest Enrolments</Card.Title>
    </Card.Header>
    <Table className="my-0">
      <thead>
        <tr>
          <th>Course Name</th>
          <th>Username</th>
          <th>Enrolment Date</th>
          <th>Paid Amount</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row) => (
          <tr>
            <td>{row.course?.name}</td>
            <td>{row.contact_number}</td>
            <td>{moment(row.created_at).format("MMM DD, YYYY hh:mm a")}</td>
            <td>{row.paid_amount}</td>
            <td>
              {row.status === "accepted" ? "Enrolled" : toTitleCase(row.status)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Card>
);

export default Projects;
