import axios from "axios";
import { removeLocalStorageData, setLocalStorageData } from "../storage";
import { refreshAccessToken } from "./queries/auth";

const axiosInstance = axios.create();
export const axiosPublicInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.status === 401) {
//       // refresh api call with refresh token
//     }
//     throw (
//       error.response?.data || {
//         success: false,
//         message: "Something went wrong",
//       }
//     );
//   }
// );

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401) {
      if (!isRefreshing && localStorage.getItem("refresh_token")) {
        isRefreshing = true;

        refreshAccessToken({
          refresh_token: localStorage.getItem("refresh_token"),
        }).then((newToken) => {
          isRefreshing = false;
          const access_token = newToken?.data?.access_token;
          setLocalStorageData("access_token", access_token);
          onRrefreshed(access_token);
        });
      }

      if (!localStorage.getItem("refresh_token")) {
        removeLocalStorageData("access_token");
        removeLocalStorageData("refresh_token");
        throw (
          error.response?.data || {
            success: false,
            message: "Something went wrong",
          }
        );
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers["Authorization"] = "Bearer " + token;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      throw (
        error.response?.data || {
          success: false,
          message: "Something went wrong",
        }
      );
    }
  }
);

export default axiosInstance;
