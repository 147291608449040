import inputTypes from "constants/form/inputTypes";

const characteristicFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "title",
    labelText: "Title",
    placeHolder: "Enter Title",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "title_bn",
    labelText: "Title (Bangla)",
    placeHolder: "Enter Title (Bangla)",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "overview",
    labelText: "Overview",
    placeHolder: "Enter Overview",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "overview_bn",
    labelText: "Overview (Bangla)",
    placeHolder: "Enter Overview (Bangla)",
    rules: { required: true },
  },
];

export default characteristicFields;
