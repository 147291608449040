import inputTypes from "../../../constants/form/inputTypes";
import { getCourseForDropdown } from "../../../services/api/queries/course";

export const couponUsageFilterMeta = [
  {
    className: "col-6 min-width-20",
    inputType: inputTypes.TEXT,
    key: "code_name",
    labelText: "Coupon Code",
  },
  {
    className: "col-6 min-width-20",
    inputType: inputTypes.SELECT,
    key: "course",
    labelText: "Course",
    isID: true,
    lookupQuery: getCourseForDropdown,
  },
];
