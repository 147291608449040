import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createEnrolment,
  getEnrolment,
  updateEnrolment,
} from "../../../services/api/queries/enrolment";
import enrolmentEditFields from "../formFields/enrolmentEditFields";
import enrolmentFields from "../formFields/enrolmentFields";
import moment from "moment";

const enrolmentStatusMap = {
  pending: "Pending",
  accepted: "Enrolled",
  rejected: "Rejected",
};

const enrolmentListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Course Name",
        accessor: "course.name",
      },
      {
        Header: "Student Name",
        accessor: "name",
      },
      {
        Header: "Enrolment Date",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY hh:mm a"),
      },
      {
        Header: "Paid Amount",
        accessor: "paid_amount",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => enrolmentStatusMap[value] || value,
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateEnrolment}
              fields={enrolmentEditFields}
              heading={"Edit an Enrolment!"}
              openerText="Edit"
              defaultValues={defaultValues}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Enrolment updated!"
              openerClassName="w-100"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createEnrolment}
        fields={enrolmentFields}
        heading={"Add an Enrolment!"}
        openerText="+ Add Enrolment"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Enrolment Added!"
      />,
    ],
    queryService: getEnrolment,
    addButtonText: "+ Add Insturctor",
  };
};

export default enrolmentListViewMeta;
