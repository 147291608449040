import inputTypes from "../../../constants/form/inputTypes";
import { getFaqTypeForDropdown } from "../../../services/api/queries/faq";

export const faqFilterMeta = [
  {
    className: "col-6 min-width-20",
    inputType: inputTypes.SELECT,
    key: "faq_type",
    labelText: "Faq Type",
    isID: true,
    lookupQuery: getFaqTypeForDropdown,
  },
];
