import React from "react";
import { Pie } from "react-chartjs-2";

import { Card, Table } from "react-bootstrap";

import usePalette from "../../../hooks/usePalette";

const PieChart = ({ pieData }) => {
  const palette = usePalette();
  const enrollmentMap = {
    manual: "Offline",
    regular: "Online",
    affiliate: "Affiliate",
  };

  const data = {
    labels: pieData?.map((d) => enrollmentMap[d.e_type]),
    datasets: [
      {
        data: pieData?.map((d) => d.total),
        backgroundColor: [
          palette.primary,
          palette.warning,
          palette.danger,
          "#E8EAED",
        ],
        borderWidth: 5,
        borderColor: palette.white,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: true,
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="py-3">
            <div className="chart chart-xs">
              <Pie data={data} options={options} />
            </div>
          </div>

          <Table className="mb-0">
            <thead>
              <tr>
                <th>Enrollment Type</th>
                <th className="text-end">Total</th>
              </tr>
            </thead>
            <tbody>
              {pieData?.map((d) => (
                <tr key={d?.e_type}>
                  <td>{enrollmentMap[d.e_type]}</td>
                  <td className="text-end">{d.total}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PieChart;
