import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import OtpInput from "react-otp-input-rc-17";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import {
  resetPassword,
  verifyResetPassword,
} from "../../services/api/queries/users";
import NotyfContext from "../../contexts/NotyfContext";
import { SIGN_IN } from "../../constants/routes";

function ResetPassword() {
  const [emailEnter, setEmailEnter] = useState(true);
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const password = useRef({});

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
  });

  password.current = watch("password", "");

  const onSubmit = async (data) => {
    try {
      const res = await verifyResetPassword(data);
      if (!res.success) throw res.data;
      const message = res.data.Message;
      notyf.open({
        type: "success",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
      navigate(SIGN_IN);
      setEmailEnter(false);
    } catch (error) {
      const message = error.error || "Something went wrong";
      notyf.open({
        type: "danger",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  };

  const sendOtp = async () => {
    try {
      const validEmail = await trigger(["email"]);
      if (!validEmail) {
        return;
      }
      const res = await resetPassword({ email: getValues("email") });
      if (!res.success) throw res;
      const message = res.data.Message;
      notyf.open({
        type: "success",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
      setEmailEnter(false);
    } catch (error) {
      const message = error.error || "Something went wrong";
      notyf.open({
        type: "danger",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {emailEnter ? (
        <>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            {...register("email", { required: "Email is required!" })}
          />
          {errors.email && (
            <Form.Text className="text-danger">
              {errors.email.message}
            </Form.Text>
          )}
          <br />
          <Button
            type="button"
            className="mt-3 display-block"
            onClick={sendOtp}
          >
            Send OTP
          </Button>
        </>
      ) : (
        <>
          <Form.Label className="mt-3">OTP</Form.Label>
          <Controller
            control={control}
            name="otp"
            render={({
              field: { onChange, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
            }) => {
              return (
                <>
                  <OtpInput
                    onChange={onChange}
                    numInputs={5}
                    htmlAttrs={{
                      autocomplete: "off",
                    }}
                    value={value}
                    inputStyle={"w-100"}
                    inputRef={ref}
                  />
                  {error && (
                    <Form.Text className="text-danger">
                      {error.otp?.message || "Invalid OTP"}
                    </Form.Text>
                  )}
                </>
              );
            }}
          />

          <Form.Label className="mt-3">New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your new password"
            {...register("password", { required: "Password is required!" })}
          />
          {errors.password && (
            <Form.Text className="text-danger">
              {errors.password.message}
            </Form.Text>
          )}
          <Form.Label className="mt-3">Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your email"
            {...register("password2", {
              validate: (value) =>
                value === password.current || "The passwords do not match",
            })}
          />
          {errors.password2 && (
            <Form.Text className="text-danger">
              {errors.password2.message}
            </Form.Text>
          )}
          <br />
          <Button type="submit" className="mt-3 display-block">
            Submit
          </Button>
        </>
      )}
    </Form>
  );
}

export default ResetPassword;
