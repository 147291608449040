import inputTypes from "constants/form/inputTypes";

const writtenEvaluationFields = [
  {
    className: "mb-3 col-12 d-inline-block",
    inputType: inputTypes.TEXTAREA,
    key: "answer",
    labelText: "Answer",
    placeHolder: "No Answer",
    disabled: true,
  },
  {
    className: "mb-3 col-4 d-inline-block",
    inputType: inputTypes.NUMBER,
    key: "mark",
    labelText: "Mark",
    placeHolder: "Enter Value",
    rules: { required: true },
  },
];

export default writtenEvaluationFields;
