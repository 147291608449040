import inputTypes from "constants/form/inputTypes";

const templateFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Template Name",
    placeHolder: "Enter Template Name",
    rules: { required: true },
    editable: true,
  },
  {
    className: "mb-3",
    inputType: inputTypes.NUMBER,
    key: "total_marks",
    labelText: "Total Marks",
    placeHolder: "Enter Total Question",
    rules: { required: true },
    editable: true,
  },
  {
    className: "mb-3",
    inputType: inputTypes.CHECKBOX,
    key: "is_sectionize",
    labelText: "Is Sectionized",
    editable: false,
  },
];

export default templateFields;
