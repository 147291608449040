import inputTypes from "../../../constants/form/inputTypes";

export const characteristicsFilterMeta = [
  {
    className: "col-6 min-width-20",
    inputType: inputTypes.TEXT,
    key: "search",
    labelText: "Title",
  },
];
