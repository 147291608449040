import moment from "moment";
import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createCircular,
  deleteCircular,
  getCircular,
  updateCircular,
} from "../../../services/api/queries/circular";
import circularFields from "../formFields/circularFields";

const circularListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Company Logo",
        accessor: "company_logo",
        align: "center",
        Cell: ({ value }) => (
          <div style={{ textAlign: "center" }}>
            <img src={value} alt="Logo" width="32px" height="32px" />
          </div>
        ),
      },
      {
        Header: "Company Name",
        accessor: "company_name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateCircular}
              fields={circularFields}
              heading={"Edit a Circular!"}
              openerText="Edit"
              defaultValues={{
                ...defaultValues,
                start_date: moment(defaultValues.start_date).format(
                  "YYYY-MM-DD"
                ),
                end_date: moment(defaultValues.end_date).format("YYYY-MM-DD"),
                courses: defaultValues.all_courses
                  ? ["select_all"]
                  : defaultValues.courses,
              }}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Circular updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteCircular}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this Circular?"
              heading="Delete Circular"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createCircular}
        fields={circularFields}
        heading={"Create a Circular!"}
        openerText="+ Add Circular"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Circular Created!"
      />,
    ],
    queryService: getCircular,
    addButtonText: "+ Add Circular",
    modelName: "circular",
  };
};

export default circularListViewMeta;
