import inputTypes from "constants/form/inputTypes";
import { getCourseForDropdown } from "services/api/queries/course";

const noticeFields = [
  {
    className: "col-5",
    inputType: inputTypes.SELECT,
    key: "course",
    labelText: "Course",
    placeholder: "Course Name",
    lookupQuery: getCourseForDropdown,
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "title",
    labelText: "Title",
    placeHolder: "Title",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "description",
    labelText: "Description",
    placeHolder: "Description",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "status",
    labelText: "Status",
    placeHolder: "Status",
    options: [
      {
        label: "Draft",
        value: "draft",
      },
      {
        label: "Published",
        value: "published",
      },
    ],
    rules: { required: true },
  },
];

export default noticeFields;
