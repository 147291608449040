import inputTypes from "constants/form/inputTypes";
import { getCategoryForDropdown } from "../../../services/api/queries/category";

const subCategoryFields = [
  {
    className: "col-5",
    inputType: inputTypes.SELECT,
    key: "category",
    labelText: "Category",
    placeholder: "Category Name",
    lookupQuery: getCategoryForDropdown,
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Sub-category Name",
    placeHolder: "Sub-category Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name_bn",
    labelText: "Sub-category Bangla Name",
    placeHolder: "Sub-category Bangla Name",
    rules: { required: true },
  },
];

export default subCategoryFields;
