import { createContext, useEffect, useReducer } from "react";
import { isValidToken, setSession } from "../utils/jwt";
import { getLocalStorageData, removeLocalStorageData } from "services/storage";
import { login, logout } from "../services/api/queries/auth";
import { getProfile } from "services/api/queries/users";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const UPDATE_USER = "UPDATE_USER";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        access_token: action.payload.access_token,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        access_token: action.payload.access_token,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        access_token: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const access_token = getLocalStorageData("access_token");
        // const refresh_token = getLocalStorageData("refresh_token");
        if (access_token) {
          const response = await getProfile();
          // setSession(access_token, refresh_token);

          if (!response.success) {
            setSession(null, null);
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          } else {
            const user = response.data;
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user,
                access_token: access_token,
              },
            });
          }
        } else {
          setSession(null, null);
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        setSession(null, null);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (username, password) => {
    const response = await login({ username, password });
    if (!response.success) throw response;
    const { access_token, refresh_token } = response.data;
    setSession(access_token, refresh_token);

    const res = await getProfile();
    if (!res.success) {
      setSession(null, null);
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    } else {
      const user = res.data;
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: true,
          user,
          access_token: access_token,
          refresh_token: refresh_token,
        },
      });
    }
    // localStorage.setItem("access_token", access_token);
  };

  const updateUser = async (user) => {
    dispatch({
      type: UPDATE_USER,
      payload: {
        user: user,
      },
    });
  };

  const signOut = async () => {
    await logout();
    setSession(null, null);
    dispatch({ type: SIGN_OUT });
    removeLocalStorageData("refresh_token");
    removeLocalStorageData("access_token");
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        resetPassword,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
