import inputTypes from "../../../constants/form/inputTypes";
import { getCategoryForDropdown } from "../../../services/api/queries/category";
import { getCourseForFilter } from "../../../services/api/queries/course";
import { getSubCategoryForDropdown } from "../../../services/api/queries/subcategory";

export const courseFilterMeta = [
  {
    className: "col-4 min-width-20",
    inputType: inputTypes.SELECT,
    key: "search",
    labelText: "Course Name",
    lookupQuery: getCourseForFilter,
    formatCreateLabel: (userInput) => `Search for ${userInput}`,
    createOptionPosition: "first",
  },
  {
    className: "col-3 min-width-20",
    inputType: inputTypes.SELECT,
    key: "category",
    isID: true,
    labelText: "Category",
    lookupQuery: getCategoryForDropdown,
  },
  {
    className: "col-3 min-width-20",
    inputType: inputTypes.SELECT,
    key: "sub_category",
    isID: true,
    labelText: "Sub-category",
    lookupQuery: getSubCategoryForDropdown,
  },
  {
    className: "col-3 min-width-20",
    inputType: inputTypes.SELECT,
    key: "status",
    labelText: "Status",
    options: [
      {
        label: "Pending",
        value: "pending",
      },
      {
        label: "Published",
        value: "published",
      },
      {
        label: "Draft",
        value: "draft",
      },
      {
        label: "Enroll Disabled",
        value: "enroll_disabled",
      },
    ],
  },
];
