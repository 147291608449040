import inputTypes from "constants/form/inputTypes";

const studentReviewFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "review",
    labelText: "Review",
    disabled: true,
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "review_bn",
    labelText: "Review (Bangla)",
    disabled: true,
  },
  {
    className: "mb-3",
    inputType: inputTypes.NUMBER,
    key: "rating",
    labelText: "Rating",
    disabled: true,
  },
  {
    className: "col-4 pb-3",
    inputType: inputTypes.SELECT,
    key: "status",
    labelText: "Status",
    placeholder: "Select Status",
    options: [
      {
        label: "Accepted",
        value: "accepted",
      },
      {
        label: "Rejected",
        value: "rejected",
      },
    ],
  },
  {
    className: "pb-3",
    inputType: inputTypes.CHECKBOX,
    key: "show_in_landing",
    labelText: "Show in Landing Page:",
    rules: { required: false },
  },
];

export default studentReviewFields;
