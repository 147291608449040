import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import topicFields from "../../configs/meta/formFields/topicFields";
import { createCourseTopic } from "../../services/api/queries/course";
import { orderTable } from "../../services/api/queries/ordering";
import { eventBus } from "../../services/eventBus";
import { moveElement } from "../../utils/urls";
import ModalFormOpener from "../actions/ModalFormOpener";
import CourseTopicAccordion from "./CourseTopicAccordion";

const CourseTopicList = ({
  topics,
  setTopics,
  course_id,
  setIsLoading,
  modelName,
}) => {
  const handleDragEnd = async ({ source, destination }) => {
    if (
      !Number.isInteger(source?.index) ||
      !Number.isInteger(destination?.index)
    )
      return;
    const newValues = moveElement(
      [...(topics || [])],
      source.index,
      destination.index
    );
    const data = newValues.map((val, i) => ({ id: val.id, ordering: i + 1 }));
    setTopics(newValues);

    const res = orderTable({ data, model_name: modelName })
      .then((info) => {
        const res = info;
        if (!res.success) throw res;
        return res;
      })
      .catch((err) => {
        eventBus.publish("refresh_topics");
      });
    return res;
  };

  return (
    <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
      <Droppable droppableId="topics">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            key="droppable-topic"
            className="topics"
          >
            {topics.map((item, index) => {
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.id?.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      key={item.id}
                      className=" d-flex topic-contents"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <span
                        {...provided.dragHandleProps}
                        key="bars"
                        className="draggable-bars"
                      >
                        <FontAwesomeIcon
                          icon={faBars}
                          className="me-3 mt-2"
                          size="lg"
                        />
                      </span>
                      <span className="w-100" key="topic">
                        <CourseTopicAccordion
                          index={index}
                          item={item}
                          course_id={course_id}
                        />
                      </span>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <section>
        <ModalFormOpener
          submitService={createCourseTopic}
          fields={topicFields}
          heading={"Add a Topic!"}
          openerText="+ Add Topic"
          refreshEvent="refresh_topics"
          size="lg"
          successMessage="Topic Created"
          appendPayload={{ course: course_id }}
          openerClassName="btn btn-primary mt-4"
        />
      </section>
    </DragDropContext>
  );
};

export default CourseTopicList;
