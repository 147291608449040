import React from "react";
import { Container } from "react-bootstrap";
import characteristicListViewMeta from "configs/meta/listViewMeta/characteristicListViewMeta";
import ListView from "../../../components/listView/ListView";
import { characteristicsFilterMeta } from "configs/meta/filterMeta/characteristicFilterMeta";

const Characteristic = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView
        filters={characteristicsFilterMeta}
        meta={characteristicListViewMeta()}
        title="Characteristic List"
      />
    </Container>
  </React.Fragment>
);

export default Characteristic;
