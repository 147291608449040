import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const createNotice = async (data) => {
  return await adminApi({
    endpoint: endpoints.COURSE_NOTICE,
    method: "post",
    data: data,
  });
};

export const getNotice = async (data) => {
  return await adminApi({
    endpoint: endpoints.COURSE_NOTICE,
    method: "get",
    params: data,
  });
};

export const updateNotice = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.COURSE_NOTICE}${data.id}/`,
    method: "patch",
    data: data,
  });
};

export const deleteNotice = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.COURSE_NOTICE}${data.id}/`,
    method: "delete",
  });
};
