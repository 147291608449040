import { getCourseForDropdown } from "services/api/queries/course";
import inputTypes from "../../../constants/form/inputTypes";

export const NoticeFilterMeta = [
  {
    className: "col-6 min-width-20",
    inputType: inputTypes.TEXT,
    key: "search",
    labelText: "Title",
  },
  {
    className: "col-6 min-width-20",
    inputType: inputTypes.SELECT,
    key: "course",
    labelText: "Course",
    isID: true,
    lookupQuery: getCourseForDropdown,
  },
];
