import moment from "moment";
import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import LinkOpener from "../../../components/actions/LinkOpener";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import Price from "../../../components/formatter/Price";
import {
  createCourse,
  deleteCourse,
  getCourse,
  updateCourse,
} from "../../../services/api/queries/course";
import courseFields from "../formFields/courseFields";

const courseListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Course Name",
        accessor: "name",
      },
      {
        Header: "Category",
        accessor: "category.name",
      },
      {
        Header: "Publish Date",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: ({ value }) => (
          <p
            className={
              moment(value).isBefore(moment.now()) ? "text-danger" : ""
            }
          >
            {moment(value).format("MMM DD, YYYY")}
          </p>
        ),
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: ({ cell }) => (
          <Price
            price={cell.row.original.regular_price}
            discount={cell.row.original.discount_amount}
          />
        ),
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateCourse}
              fields={courseFields.map((field) => {
                if (field.key === "end_date") {
                  return {
                    ...field,
                    hide: defaultValues.accessibility === "lifetime",
                  };
                } else if (
                  field.key === "regular_price" ||
                  field.key === "discount_value" ||
                  field.key === "discount_type"
                ) {
                  return {
                    ...field,
                    hide: defaultValues.fee_type === "free",
                    show: !(defaultValues.fee_type === "free"),
                  };
                }
                return field;
              })}
              heading={"Edit a Course!"}
              openerText="Edit"
              parentId="modal_span"
              defaultValues={{
                ...defaultValues,
                category: defaultValues.category.id,
                sub_category: defaultValues.sub_category.id,
                cover_photo: [defaultValues.cover_photo],
                amenities: defaultValues.amenities.eng?.map((amenity) => ({
                  name: amenity,
                })),
                amenities_bn: defaultValues.amenities.bng?.map((amenity) => ({
                  name: amenity,
                })),
              }}
              refreshEvent="refresh_table"
              size="xl"
              doNotReset={true}
              successMessage="Course updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <LinkOpener
              key="course_builder"
              openerText="Course Builder"
              navigateTo={`/pages/course/course-builder?course_id=${defaultValues.id}`}
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteCourse}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this course?"
              heading="Delete Course"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createCourse}
        fields={courseFields}
        heading={"Create a Course!"}
        openerText="+ Add Course"
        parentId="modal_span"
        refreshEvent="refresh_table"
        size="xl"
        doNotReset={true}
        successMessage="Course Created!"
        openerClassName="btn btn-primary"
      />,
    ],
    queryService: getCourse,
    modelName: "course",
    addButtonText: "+ Add Course",
  };
};

export default courseListViewMeta;
