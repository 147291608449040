import inputTypes from "constants/form/inputTypes";

const sectionFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Section Name",
    placeHolder: "Enter Section Name",
    rules: { required: true },
  },
  // {
  //   className: "mb-3",
  //   inputType: inputTypes.TEXT,
  //   key: "name_bn",
  //   labelText: "Bangla Name",
  //   placeHolder: "Enter Bangla Name",
  //   rules: { required: true },
  // },
  {
    className: "mb-3",
    inputType: inputTypes.NUMBER,
    key: "total_marks",
    labelText: "Marks",
    placeHolder: "Enter Marks",
    rules: { required: true },
  },
];

export default sectionFields;
