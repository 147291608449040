const endpoints = {
  ANSWERS: "forums/admin/questions/answers/",
  ANSWER_QUESTIONS: "forums/admin/questions/answers/",
  BLOG: "blogs/admin/blogs/",
  BLOG_CATEGORY: "blogs/admin/categories/",
  CATEGORY: "courses/admin/categories/",
  CHANGE_PASSWORD: "accounts/user/password-change/",
  CHARACTERISTIC: "configurations/admin/characteristics/",
  CIRCULAR: "circulars/admin/circulars/",
  CLASS_NOTE: "course-contents/admin/notes/",
  COUPON: "coupons/admin/coupons/",
  COUPON_USAGES: "coupons/admin/coupon-usages/",
  COURSE: "courses/admin/courses/",
  COURSE_LECTURE: "course-contents/admin/lectures/",
  COURSE_NOTICE: "course-contents/admin/notices/",
  COURSE_TOPIC: "course-contents/admin/topics/",
  COURSE_TOPIC_CONTENT: "course-contents/admin/topic-contents/",
  DASHBOARD: "accounts/admin/dashboard/",
  DASHBOARD_ENROLLMENTS: "enrollments/admin/dashboard-data/",
  ENROLMENT: "enrollments/admin/enrollments/",
  EXAMS: "exams/admin/exams/",
  FILE_UPLOAD: "s3-upload/",
  FREE_EXAMS: "exams/admin/free-exams/",
  INSTRUCTOR: "courses/admin/instructors/",
  JOIN_FORUM: "forums/admin/join-forum/",
  LOGOUT: "accounts/user/logout/",
  ORDERING: "ordering-update/",
  QUESTION: "questions/admin/questions/",
  QUESTIONS: "forums/admin/questions/",
  QUESTION_DETAILS: "forums/admin/question-detail/",
  QUIZ: "exams/admin/exams/",
  REFRESH_TOKEN: "accounts/public/admin-refresh-token/",
  RESET_PASSWORD: "accounts/public/reset-password/",
  RESET_PASSWORD_VERIFY: "accounts/public/verify-reset-password/",
  REVIEWS: "courses/admin/reviews/",
  BOOK_REVIEWS: "shops/admin/book-reviews/",
  SECTION: "questions/admin/sections/",
  SITE_INFO: "configurations/admin/site-info/",
  SUBSCRIPTION: "configurations/admin/subscribers/",
  ORDER: "shops/admin/book-orders/",
  FAQ_TYPE: "configurations/admin/faq-types/",
  FAQ: "configurations/admin/faqs/",
  SUB_CATEGORY: "courses/admin/sub-categories/",
  TEMPLATE: "questions/admin/templates/",
  TEMPLATE_QUESTIONS: "questions/admin/templates/questions/",
  TOPIC_CONTENT_ORDERING: "course-contents/admin/topic-contents/ordering/",
  UPDATE_OPTIONS: "questions/admin/options/",
  USERS: "accounts/admin/users/",
  USER_PROFILE: "accounts/user/profile/",
  USER_LOGIN: "accounts/public/login/",
  WRITTEN_EVALUATION: "exams/admin/questions/written-evaluation/",
  UNANSWERED_QUESTIONS: "forums/admin/unanswered-questions/",
};
export default endpoints;
