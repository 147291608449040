import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import { setLocalStorageData } from "services/storage";

const isValidToken = (access_token) => {
  if (!access_token) {
    return false;
  }
  const decoded = jwtDecode(access_token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (access_token, refresh_token) => {
  if (access_token) {
    setLocalStorageData("access_token", access_token);
    setLocalStorageData("refresh_token", refresh_token);
  } else {
    // removeLocalStorageData("access_token");
    // removeLocalStorageData("refresh_token");
  }
};

export { verify, sign, isValidToken, setSession };
