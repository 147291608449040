import inputTypes from "constants/form/inputTypes";

const orderFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "invoice_no",
    labelText: "Invoice No",
    placeHolder: "Invoice NO",
    disabled: true,
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.NUMBER,
    key: "quantity",
    labelText: "Quantity",
    placeHolder: "Quantity",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "first_name",
    labelText: "First Name",
    placeHolder: "First Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "last_name",
    labelText: "Last Name",
    placeHolder: "Last Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "district",
    labelText: "District",
    placeHolder: "District",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "address",
    labelText: "Address",
    placeHolder: "Address",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "primary_phone",
    labelText: "Primary Phone",
    placeHolder: "Primary Phone",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "secondary_phone",
    labelText: "Secondary Phone",
    placeHolder: "Secondary Phone",
    rules: { required: false },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "email",
    labelText: "Email",
    placeHolder: "Email",
    rules: { required: false },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "note",
    labelText: "Note",
    placeHolder: "Note",
    rules: { required: false },
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "status",
    labelText: "Status",
    placeHolder: "Status",
    options: [
      {
        label: "Pending",
        value: "pending",
      },
      {
        label: "Confirmed",
        value: "confirmed",
      },
      {
        label: "Cancelled",
        value: "cancelled",
      },
    ],
    rules: { required: true },
  },
];

export default orderFields;
