import inputTypes from "constants/form/inputTypes";
import { getTemplateForDropdown } from "../../../services/api/queries/template";

const quizFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Quiz Name",
    placeHolder: "Enter Quiz Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "instruction",
    labelText: "Instructions",
    placeHolder: "Enter Instructions",
    rules: { required: true },
  },
  {
    className: "col-5 mb-3",
    inputType: inputTypes.SELECT,
    key: "template",
    labelText: "Template",
    placeholder: "Template Name",
    lookupQuery: getTemplateForDropdown,
    rules: { required: true },
  },
  {
    className: "col-5 mb-3",
    inputType: inputTypes.NUMBER,
    key: "total_question",
    labelText: "Total Question",
    placeHolder: "Enter Total Question",
    rules: { required: true },
  },
  {
    className: "mb-3",
    childrenClassName: "d-flex",
    inputType: inputTypes.RADIO,
    key: "duration_select",
    labelText: "Quiz Duration",
    rules: { required: true },
    options: [
      {
        label: "Time Duration",
        value: "time_duration",
      },
      {
        label: "No Limit",
        value: "no_limit",
      },
    ],
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.NUMBER,
    key: "hours",
    placeHolder: "Enter Hours",
    hide: true,
    labelText: "Enter Hours",
    dependencies: ["duration_select"],
    onDependencyValueChange: (
      { duration_select },
      key,
      { toggleVisibility }
    ) => {
      toggleVisibility("hours", duration_select !== "time_duration");
    },
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.NUMBER,
    key: "minutes",
    placeHolder: "Enter Minutes",
    hide: true,
    labelText: "Enter Minutes",
    dependencies: ["duration_select"],
    onDependencyValueChange: (
      { duration_select },
      key,
      { toggleVisibility }
    ) => {
      toggleVisibility("minutes", duration_select !== "time_duration");
    },
  },

  {
    className: "mb-3",
    childrenClassName: "d-flex",
    inputType: inputTypes.RADIO,
    key: "start_select",
    labelText: "Start Exam",
    rules: { required: true },
    options: [
      {
        label: "Date time",
        value: "date_time",
      },
      {
        label: "Instant",
        value: "instant",
      },
    ],
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.DATE_TIME,
    key: "start_date",
    placeHolder: "Select Start Time",
    labelText: "Start Time",
    hide: true,
    dependencies: ["start_select"],
    onDependencyValueChange: ({ start_select }, key, { toggleVisibility }) => {
      toggleVisibility("start_date", start_select !== "date_time");
    },
  },
  {
    className: "mb-3",
    childrenClassName: "d-flex",
    inputType: inputTypes.RADIO,
    key: "publish_select",
    labelText: "Publish Result",
    rules: { required: true },
    options: [
      {
        label: "Publish Time",
        value: "publish_time",
      },
      {
        label: "Instant Result",
        value: "instant",
      },
    ],
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.DATE,
    key: "result_publish_date",
    placeHolder: "Select Publish Time",
    labelText: "Publish Time",
    hide: true,
    dependencies: ["publish_select"],
    onDependencyValueChange: (
      { publish_select },
      key,
      { toggleVisibility }
    ) => {
      toggleVisibility(
        "result_publish_date",
        publish_select !== "publish_time"
      );
    },
  },

  {
    className: "mb-3",
    childrenClassName: "d-flex",
    inputType: inputTypes.RADIO,
    key: "quiz_attempts",
    labelText: "Quiz Attempt",
    rules: { required: true },
    options: [
      {
        label: "Limited",
        value: "limited",
      },
      {
        label: "Unlimited",
        value: "unlimited",
      },
    ],
  },
  {
    className: "mb-3 col-4 d-inline-block",
    inputType: inputTypes.NUMBER,
    key: "attempt_limit",
    placeHolder: "Enter Value",
    labelText: "Attempts",
    dependencies: ["quiz_attempts"],
    hide: true,
    onDependencyValueChange: ({ quiz_attempts }, key, { toggleVisibility }) => {
      toggleVisibility("attempt_limit", quiz_attempts !== "limited");
    },
  },
  {
    className: "col-6 pb-3 d-inline-block",
    inputType: inputTypes.FILE,
    key: "solution_pdf",
    labelText: "Solution",
    multiple: false,
  },
];

export default quizFields;
