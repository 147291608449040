import { getCourseForDropdown } from "services/api/queries/course";
import inputTypes from "../../../constants/form/inputTypes";

export const enrolmentFilterMeta = [
  {
    className: "col-5",
    inputType: inputTypes.TEXT,
    key: "search",
    labelText: "Phone Number",
  },
  {
    className: "col-5",
    inputType: inputTypes.SELECT,
    key: "course",
    labelText: "Course",
    isID: true,
    lookupQuery: getCourseForDropdown,
  },
  {
    className: "col-5 mt-1",
    inputType: inputTypes.DATE,
    key: "created_at_after",
    labelText: "From Date",
    isID: true,
  },
  {
    className: "col-5 mt-1",
    inputType: inputTypes.DATE,
    key: "created_at_before",
    labelText: "To Date",
    isID: true,
  },
  {
    className: "col-5 mt-1",
    inputType: inputTypes.SELECT,
    key: "duration",
    labelText: "Duration",
    isID: true,
    options: [
      { label: "Today", value: "day" },
      { label: "This Week", value: "week" },
      { label: "This Month", value: "month" },
    ],
  },
];
