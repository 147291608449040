import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import CourseTopicList from "../../../components/course/CourseTopicList";
import Loader from "../../../components/Loader";
import { getTopic } from "../../../services/api/queries/course";
import { eventBus } from "../../../services/eventBus";

const CourseBuilder = () => {
  const [topicData, setTopicData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const performQuery = useCallback((params) => {
    setIsLoading(true);
    const res = getTopic(params).then((info) => {
      const { data } = info;
      setTopicData(data || []);
      setIsLoading(false);
      return info;
    });
    return res;
  }, []);

  useEffect(() => {
    eventBus.subscribe("refresh_topics", () =>
      performQuery({ course: searchParams.get("course_id") })
    );

    return () => {
      eventBus.unsubscribe("refresh_topics");
    };
  }, [performQuery, searchParams]);

  useEffect(() => {
    if (searchParams.get("course_id"))
      performQuery({ course: searchParams.get("course_id") });
  }, [performQuery, searchParams]);

  return (
    <React.Fragment>
      <Helmet title="Course Builder Page" />
      <Loader isLoading={isLoading}>
        <Container fluid className="p-0">
          <CourseTopicList
            topics={topicData}
            setTopics={setTopicData}
            course_id={searchParams.get("course_id")}
            setIsLoading={setIsLoading}
            modelName="topic"
          />
        </Container>
      </Loader>
    </React.Fragment>
  );
};

export default CourseBuilder;
