import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const getWrittenQuestionForEvaluation = async (data) => {
  return await adminApi({
    endpoint: endpoints.WRITTEN_EVALUATION,
    method: "get",
    params: data,
  });
};

export const updateWrittenEvaluation = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.WRITTEN_EVALUATION}${data.id}/`,
    method: "patch",
    data: {
      mark: data.mark,
    },
  });
};
