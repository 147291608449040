import React from "react";
import { Container } from "react-bootstrap";
import writtenEvaluationListViewMeta from "configs/meta/listViewMeta/writtenEvaluationListViewMeta";
import ListView from "../../../components/listView/ListView";
import { writtenEvaluationFilterMeta } from "configs/meta/filterMeta/writtenEvaluationFilterMeta";

const WrittenEvaluation = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView
        filters={writtenEvaluationFilterMeta}
        meta={writtenEvaluationListViewMeta()}
        title="Written Question List"
      />
    </Container>
  </React.Fragment>
);

export default WrittenEvaluation;
