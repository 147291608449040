import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createFreeExam,
  deleteFreeExam,
  getFreeExams,
  updateFreeExam,
} from "../../../services/api/queries/freeExam";
import freeExamFields from "../formFields/freeExamFields";

const freeExamListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Duration",
        accessor: "duration",
        Cell: ({ value }) => value || "No Limit",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateFreeExam}
              fields={freeExamFields.map((val) => {
                return val.key === "hours" || val.key === "minutes"
                  ? { ...val, hide: !defaultValues.duration }
                  : val;
              })}
              heading={"Edit a Exam!"}
              openerText="Edit"
              defaultValues={{
                ...defaultValues,
                duration_select: defaultValues.duration
                  ? "time_duration"
                  : "no_limit",
                template: defaultValues.template.id,
                hours: defaultValues.duration
                  ? parseInt(defaultValues.duration?.split(":")?.[0])
                  : null,
                minutes: defaultValues.duration
                  ? parseInt(defaultValues.duration?.split(":")?.[1])
                  : null,
              }}
              refreshEvent="refresh_table"
              size="xl"
              doNotReset={true}
              successMessage="Exam updated!"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteFreeExam}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this Exam?"
              heading="Delete Exam"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createFreeExam}
        fields={freeExamFields}
        heading={"Create a Exam!"}
        openerText="+ Add Exam"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="xl"
        successMessage="Exam Created!"
      />,
    ],
    queryService: () => getFreeExams(),
    modelName: "blogcategory",
  };
};

export default freeExamListViewMeta;
