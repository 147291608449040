import React from "react";

function Price({ price, discount }) {
  return (
    <strong>
      {price - discount} BDT
      <del className="m-2 text-danger">{price} BDT</del>
    </strong>
  );
}

export default Price;
