import {
  faAngleDown,
  faAngleUp,
  faBars,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card, Collapse } from "react-bootstrap";
import classNoteFields from "../../configs/meta/formFields/classNoteFields";
import lectureFields from "../../configs/meta/formFields/lectureFields";
import quizFields from "../../configs/meta/formFields/quizFields";
import topicFields from "../../configs/meta/formFields/topicFields";
import {
  deleteCourseTopic,
  getSingleTopicContent,
  orderTopicContents,
  updateCourseTopic,
} from "../../services/api/queries/course";
import { createExam } from "../../services/api/queries/exams";
import { createLecture } from "../../services/api/queries/lectures";
import { createNote } from "../../services/api/queries/notes";
import { eventBus } from "../../services/eventBus";
import { moveElement } from "../../utils/urls";
import ConfirmActionButton from "../actions/ConfirmActionButton";
import ModalFormOpener from "../actions/ModalFormOpener";
import Loader from "../Loader";
import TopicItem from "./TopicItem";

const CourseTopicAccordion = ({ course_id, index, item }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  function CustomToggle({ topic }) {
    return (
      <div className={`tab ${open ? "tab--selected" : ""}`}>
        <h6 className="tab-label">{topic.name}</h6>

        <div className="mt-1 me-2 tab-action-icons-container">
          <ConfirmActionButton
            openerText={
              <FontAwesomeIcon
                icon={faTrash}
                className="cursor-pointer delete-icon"
                size="lg"
              />
            }
            confirmButtonName="Yes"
            refreshEvent="refresh_topics"
            queryService={deleteCourseTopic}
            value={item}
            confirmationMessage="Do you really wanna delete this?"
            heading="Delete Question"
            openerClassName="d-inline-block me-3"
          />

          <ModalFormOpener
            submitService={updateCourseTopic}
            fields={topicFields}
            heading={"Modify a Topic!"}
            openerText={
              <FontAwesomeIcon
                icon={faEdit}
                size="lg"
                className="cursor-pointer edit-icon"
              />
            }
            refreshEvent="refresh_topics"
            size="lg"
            successMessage="Topic Updated!"
            appendPayload={{
              id: topic.id,
            }}
            defaultValues={{
              ...item,
              hours: item.duration
                ? parseInt(item.duration?.split(":")?.[0])
                : null,
              minutes: item.duration
                ? parseInt(item.duration?.split(":")?.[1])
                : null,
            }}
            openerClassName="d-inline-block me-3"
          />

          <FontAwesomeIcon
            icon={!open ? faAngleDown : faAngleUp}
            aria-controls="example-collapse-text"
            onClick={() =>
              setOpen((prev) => {
                if (!prev) performQuery();
                return !prev;
              })
            }
            size="lg"
            className="me-2 cursor-pointer"
          />
        </div>
      </div>
    );
  }

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getSingleTopicContent(item.id).then((info) => {
      const { data } = info;
      setData(data || []);
      setIsLoading(false);
      return info;
    });
    return res;
  }, [item.id]);

  useEffect(() => {
    eventBus.subscribe(`refresh_topic_content__${item.id}`, () =>
      performQuery()
    );

    return () => {
      eventBus.unsubscribe(`refresh_topic_content__${item.id}`);
    };
  }, [item.id, performQuery]);

  const handleDragEnd = async ({ source, destination }) => {
    if (
      !Number.isInteger(source?.index) ||
      !Number.isInteger(destination?.index)
    )
      return;
    const newValues = moveElement(
      [...(data || [])],
      source.index,
      destination.index
    );
    console.log(newValues);

    const orderingData = newValues.map((val, i) => ({
      id: val.id,
      ordering: i + 1,
      content_type: val.content_type,
    }));

    setData(newValues);

    const res = orderTopicContents({
      data: orderingData,
    })
      .then((info) => {
        const res = info;
        if (!res.success) throw res;
        return res;
      })
      .catch((err) => {
        eventBus.publish(`refresh_topic_content__${item.id}`);
      });
    return res;
  };

  return (
    <Card className="">
      <CustomToggle topic={item} />
      <Collapse in={open}>
        <div id="example-collapse-text" className="mt-1">
          <Loader minHeight={false} isLoading={isLoading}>
            <div className="data">
              <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                <Droppable droppableId="topic_items">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      className="mb-3"
                      {...provided.droppableProps}
                    >
                      {data.map((d, index) => {
                        return (
                          <Draggable
                            draggableId={`${d.id}${d.content_type}`}
                            index={index}
                            key={`${d.id}${d.content_type}`}
                          >
                            {(provided) => (
                              <div
                                key={`${d.id}${d.content_type}`}
                                className="mt-3 d-flex"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <span {...provided.dragHandleProps}>
                                  <FontAwesomeIcon
                                    icon={faBars}
                                    className="me-3 mt-1"
                                    size="lg"
                                  />
                                </span>
                                <TopicItem
                                  item={d}
                                  refreshEvent={`refresh_topic_content__${item.id}`}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="d-block mb-3">
              <ModalFormOpener
                submitService={createLecture}
                fields={lectureFields}
                heading={"Add a Lecture!"}
                openerText="+ Lecture"
                refreshEvent={`refresh_topic_content__${item.id}`}
                size="lg"
                successMessage="Lecture Created"
                appendPayload={{
                  course: course_id,
                  topic: item.id,
                }}
                openerClassName="btn btn-primary d-inline-block me-3"
              />
              <ModalFormOpener
                submitService={createExam}
                fields={quizFields}
                heading={"Add a Quiz!"}
                openerText="+ Quiz"
                refreshEvent={`refresh_topic_content__${item.id}`}
                size="lg"
                successMessage="Quiz Created"
                appendPayload={{
                  course: course_id,
                  topic: item.id,
                }}
                openerClassName="btn btn-primary d-inline-block me-3"
              />
              <ModalFormOpener
                submitService={createNote}
                fields={classNoteFields}
                heading={"Add a Class Note!"}
                openerText="+ Class Note"
                refreshEvent={`refresh_topic_content__${item.id}`}
                size="lg"
                successMessage="Class Note Created"
                appendPayload={{
                  course: course_id,
                  topic: item.id,
                }}
                openerClassName="btn btn-primary d-inline-block"
              />
            </div>
          </Loader>
        </div>
      </Collapse>
    </Card>
  );
};

export default CourseTopicAccordion;
