import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const getOrders = async (data) => {
  const params = {
    ...data,
    status: data?.status ? data.status : "pending",
    page_size: 0,
  };
  return await adminApi({
    endpoint: endpoints.ORDER,
    method: "get",
    params: params,
  });
};

export const updateOrder = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.ORDER}${data.id}/`,
    method: "patch",
    data: data,
  });
};
