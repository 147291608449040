import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  getRegularEnrolment,
  updateEnrolment,
} from "../../../services/api/queries/enrolment";
import enrolmentEditFields from "../formFields/enrolmentEditFields";
import moment from "moment";

const enrolmentStatusMap = {
  pending: "Pending",
  accepted: "Enrolled",
  rejected: "Rejected",
};

const regularEnrolmentListView = () => {
  return {
    columns: [
      {
        Header: "Course Name",
        accessor: "course.name",
      },
      {
        Header: "Student Name",
        accessor: "name",
      },
      {
        Header: "Enrolment Date",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY hh:mm a"),
      },
      {
        Header: "Paid Amount",
        accessor: "paid_amount",
      },
      // {
      //   Header: "Gateway",
      //   accessor: "gateway",
      // },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => enrolmentStatusMap[value] || value,
      },
      // {
      //   Header: "",
      //   accessor: "actions",
      //   options: [
      //     (defaultValues) => (
      //       <ModalFormOpener
      //         key="edit"
      //         submitService={updateEnrolment}
      //         fields={enrolmentEditFields}
      //         heading={"Edit an Enrolment!"}
      //         openerText="Edit"
      //         defaultValues={defaultValues}
      //         refreshEvent="refresh_table"
      //         size="lg"
      //         doNotReset={true}
      //         successMessage="Enrolment updated!"
      //         openerClassName="w-100"
      //       />
      //     ),
      //   ],
      // },
    ],
    actions: [],
    queryService: getRegularEnrolment,
    addButtonText: "+ Add Insturctor",
  };
};

export default regularEnrolmentListView;
