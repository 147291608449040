import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "react-bootstrap";

function FilePreview({ file, deleteFile }) {
  const isImage = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(file);
  const splittedFileUrl = file?.split("/");
  return (
    <div key={file}>
      <div className="d-flex file-container">
        <div className="img-container">
          {isImage ? (
            <img src={file} alt="..." />
          ) : (
            splittedFileUrl && (
              <Badge bg="light" text="dark" className="p-2 width-200px">
                {splittedFileUrl[splittedFileUrl.length - 1]
                  .split("-")
                  .slice(1)
                  .join("-")}
              </Badge>
            )
          )}
        </div>
        <div className="icon">
          <FontAwesomeIcon
            icon={faTimes}
            className="mt-1"
            onClick={() => deleteFile(file)}
          />
        </div>
      </div>
    </div>
  );
}

export default FilePreview;
