import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const getProfile = async () => {
  return await adminApi({
    endpoint: endpoints.USER_PROFILE,
    method: "get",
  });
};

export const getUsers = async (params) => {
  return await adminApi({
    endpoint: endpoints.USERS,
    method: "get",
    params: { ...params, is_staff: "True" },
  });
};

export const createUser = async (data) => {
  return await adminApi({
    endpoint: endpoints.USERS,
    method: "post",
    data: data,
  });
};

export const updateUser = async (data) => {
  const payload = {
    ...data,
  };
  return await adminApi({
    endpoint: `${endpoints.USERS}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const resetPassword = async (data) => {
  return await adminApi({
    endpoint: endpoints.RESET_PASSWORD,
    method: "post",
    data: data,
  });
};

export const verifyResetPassword = async (data) => {
  return await adminApi({
    endpoint: endpoints.RESET_PASSWORD_VERIFY,
    method: "post",
    data: data,
  });
};

export const changePassword = async (data) => {
  return await adminApi({
    endpoint: endpoints.CHANGE_PASSWORD,
    method: "post",
    data: data,
  });
};
