import React from "react";
import { Container } from "react-bootstrap";
import { freeExamParticipantsFilterViewMeta } from "../../../configs/meta/filterMeta/freeExamParticipantsFilterViewMeta";
import ListView from "../../../components/listView/ListView";
import freeExamParticipatnsListViewMeta from "../../../configs/meta/listViewMeta/freeExamPartitionsListViewMeta";

const FreeExamParticipants = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView
        filters={freeExamParticipantsFilterViewMeta}
        meta={freeExamParticipatnsListViewMeta()}
        title="Free Exam Participants List"
      />
    </Container>
  </React.Fragment>
);

export default FreeExamParticipants;
