import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import userManagementListViewMeta from "../../../configs/meta/listViewMeta/userManagmentListViewMeta";

const UserManagement = () => {
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <ListView meta={userManagementListViewMeta()} title="User List" />
      </Container>
    </React.Fragment>
  );
};
export default UserManagement;
