import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createBookReviews,
  getBookReviews,
  updateBookReviews,
} from "../../../services/api/queries/course";
import bookReviewFields from "../formFields/bookReviewFields";

const bookReviewListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Reviewer Name",
        accessor: "reviewer_name",
      },
      {
        Header: "Reviewer Name (Bangla)",
        accessor: "reviewer_name_bn",
      },
      {
        Header: "Rating",
        accessor: "rating",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Show In B2C",
        accessor: "show_in_landing",
        Cell: ({ value }) => (value ? "✓" : "X"),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateBookReviews}
              fields={bookReviewFields}
              heading={"Edit a Review!"}
              openerText="Edit"
              parentId="modal_span"
              defaultValues={{
                ...defaultValues,
                reviewer_image: [defaultValues.reviewer_image],
                course: defaultValues.course?.id
                  ? defaultValues.course?.id
                  : defaultValues.course,
              }}
              refreshEvent="refresh_table"
              size="xl"
              doNotReset={true}
              successMessage="Review updated!"
              openerClassName="w-100"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createBookReviews}
        fields={bookReviewFields}
        heading={"Create a Review!"}
        openerText="+ Add Review"
        parentId="modal_span"
        refreshEvent="refresh_table"
        size="xl"
        doNotReset={true}
        successMessage="Review Created!"
        openerClassName="btn btn-primary"
      />,
    ],
    queryService: getBookReviews,
    modelName: "coursereview",
  };
};

export default bookReviewListViewMeta;
