/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import Discussion from "../../../components/discussion/Discussion";
import { getQuestions } from "../../../services/api/queries/forum";
import { useParams } from "react-router-dom";
import { eventBus } from "../../../services/eventBus";

const ForumDiscussion = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const params = useParams();

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getQuestions(params)
      .then((info) => {
        if (!info.success) throw info;
        setQuestions(info.data);
        return info;
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    return res;
  }, [params]);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  useEffect(() => {
    eventBus.subscribe("refresh_questions", () => performQuery());

    return () => {
      eventBus.unsubscribe("refresh_questions");
    };
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="My Courses  Page" />
      <Loader className="h-100" minHeight={false} isLoading={isLoading}>
        <div className="h-100">
          <div className="row d-flex h-100">
            <div className="col-md-12 h-100">
              <div
                className="discussion-section p-lg-5 h-100"
                style={{ cursor: "default" }}
              >
                <div className="discussion-container">
                  {questions.map((question, idx) => (
                    <div>
                      <Discussion
                        question={question}
                        course_id={params.course_id}
                      />
                      {idx !== questions.length - 1 && <hr />}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </React.Fragment>
  );
};
export default ForumDiscussion;
