import inputTypes from "../../../constants/form/inputTypes";

export const writtenEvaluationFilterMeta = [
  {
    className: "col-4 min-width-20",
    inputType: inputTypes.TEXT,
    key: "search",
    labelText: "Search",
  },
  {
    className: "col-3 min-width-20",
    inputType: inputTypes.SELECT,
    key: "written_evaluation",
    labelText: "Status",
    options: [
      {
        label: "Evaluated",
        value: "true",
      },
      {
        label: "Not Evaluated",
        value: "false",
      },
    ],
  },
];
