import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createCategory,
  deleteCategory,
  getCategory,
  updateCategory,
} from "../../../services/api/queries/category";
import categoryFields from "../formFields/categoryFields";

const categoryListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Bangla Name",
        accessor: "name_bn",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateCategory}
              fields={categoryFields}
              heading={"Edit a Category!"}
              openerText="Edit"
              defaultValues={defaultValues}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Category updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteCategory}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this category?"
              heading="Delete Category"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createCategory}
        fields={categoryFields}
        heading={"Create a Category!"}
        openerText="+ Add Category"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Category Created!"
      />,
    ],
    queryService: getCategory,
    modelName: "category",
    addButtonText: "+ Add Category",
  };
};

export default categoryListViewMeta;
