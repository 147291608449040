import moment from "moment";
import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createFaq,
  deleteFaq,
  getFaq,
  updateFaq,
} from "../../../services/api/queries/faq";
import faqFields from "../formFields/faqFields";

const faqListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Faq Type",
        accessor: "faq_type.name",
      },
      {
        Header: "Question",
        accessor: "question",
      },
      {
        Header: "Answer",
        accessor: "answer",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateFaq}
              fields={faqFields}
              heading={"Edit a Faq!"}
              openerText="Edit"
              defaultValues={{
                ...defaultValues,
                faq_type: defaultValues.faq_type.id,
              }}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Faq updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteFaq}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this Faq?"
              heading="Delete Faq"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createFaq}
        fields={faqFields}
        heading={"Create a Faq!"}
        openerText="+ Add Faq"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Faq Created!"
      />,
    ],
    queryService: getFaq,
    addButtonText: "+ Add Faq",
    modelName: "faq",
  };
};

export default faqListViewMeta;
