import inputTypes from "../../../constants/form/inputTypes";
import { getCategoryForDropdown } from "../../../services/api/queries/category";

export const subCategoryFilterMeta = [
  {
    className: "col-6 min-width-20",
    inputType: inputTypes.TEXT,
    key: "search",
    labelText: "Sub-category Name",
  },
  {
    className: "col-6 min-width-20",
    inputType: inputTypes.SELECT,
    key: "category",
    labelText: "Category",
    isID: true,
    lookupQuery: getCategoryForDropdown,
  },
];
