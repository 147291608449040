import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, Col, Card, Row } from "react-bootstrap";

import { Hash, Mail, MessageCircle } from "react-feather";

const Statistics = ({ data }) => {
  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">৳ {data?.sales?.value?.toFixed(2)}</h3>
                <p className="mb-2">Total Sales</p>
                <div className="mb-0">
                  <Badge
                    bg=""
                    className={`badge-soft-${
                      data?.sales?.growth >= 0 ? "success" : "danger"
                    } me-2`}
                  >
                    {data?.sales?.growth >= 0 && "+"}
                    {data?.sales?.growth?.toFixed(2)}
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat d-flex p-0">
                  <div className="align-middle text-info h1 p-0 m-auto mt-2">
                    ৳
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{data?.email_subscription?.value}</h3>
                <p className="mb-2">Email Subscription</p>
                <div className="mb-0">
                  <Badge
                    bg=""
                    className={`badge-soft-${
                      data?.email_subscription?.growth >= 0
                        ? "success"
                        : "danger"
                    } me-2`}
                  >
                    {data?.email_subscription?.growth >= 0 && "+"}
                    {data?.email_subscription?.growth}
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <Mail className="align-middle text-info" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{data?.students?.value}</h3>
                <p className="mb-2">Number of Students</p>
                <div className="mb-0">
                  <Badge
                    bg=""
                    className={`badge-soft-${
                      data?.students?.growth >= 0 ? "success" : "danger"
                    } me-2`}
                  >
                    {data?.students?.growth >= 0 && "+"}
                    {data?.students?.growth}
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  {/* <Hash className="align-middle text-success" /> */}
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="align-middle text-info"
                    size={"lg"}
                  />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{data?.discussion?.value}</h3>
                <p className="mb-2">New Discussions</p>
                <div className="mb-0">
                  <Badge
                    bg=""
                    className={`badge-soft-${
                      data?.discussion?.growth >= 0 ? "success" : "danger"
                    } me-2`}
                  >
                    {data?.discussion?.growth >= 0 && "+"}
                    {data?.discussion?.growth}
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <MessageCircle className="align-middle text-info" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Statistics;
