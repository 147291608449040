import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const getCourse = async (data) => {
  return await adminApi({
    endpoint: endpoints.COURSE,
    method: "get",
    params: data,
  });
};

export const getSingleCourse = async (id) => {
  return await adminApi({
    endpoint: `${endpoints.COURSE}${id}`,
    method: "get",
  });
};

export const getSingleTopicContent = async (id) => {
  return await adminApi({
    endpoint: `${endpoints.COURSE_TOPIC_CONTENT}${id}/`,
    method: "get",
  });
};

export const createCourse = async (data) => {
  const payload = {
    ...data,
    cover_photo: data.cover_photo[0],
    amenities: {
      bng: data.amenities_bn.map((amenity) => amenity.name),
      eng: data.amenities.map((amenity) => amenity.name),
    },
  };
  delete payload["amenities_bn"];
  return await adminApi({
    endpoint: endpoints.COURSE,
    method: "post",
    data: payload,
  });
};

export const updateCourse = async (data) => {
  const payload = {
    ...data,
    cover_photo: Array.isArray(data.cover_photo)
      ? data.cover_photo[0]
      : data.cover_photo,
    amenities: {
      bng: data.amenities_bn.map((amenity) => amenity.name),
      eng: data.amenities.map((amenity) => amenity.name),
    },
  };
  delete payload["amenities_bn"];
  return await adminApi({
    endpoint: `${endpoints.COURSE}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getCourseForDropdown = async (data) => {
  const res = await adminApi({
    endpoint: endpoints.COURSE,
    method: "get",
    params: { page_size: 0 },
  });

  return res.data?.map((course) => ({
    value: course.id,
    label: course.name,
  }));
};

export const getCourseForFilter = async (data) => {
  const res = await adminApi({
    endpoint: endpoints.COURSE,
    method: "get",
    params: { page_size: 0 },
  });

  return res.data?.map((course) => ({
    value: course.name,
    label: course.name,
  }));
};

export const createCourseTopic = async (data) => {
  const payload = {
    ...data,
    ordering: 1000,
    duration:
      data.hours &&
      data.minutes &&
      `${data.hours.toLocaleString({
        minimumIntegerDigits: 2,
      })}:${data.minutes.toLocaleString({ minimumIntegerDigits: 2 })}:00`,
  };
  return await adminApi({
    endpoint: endpoints.COURSE_TOPIC,
    method: "post",
    data: payload,
  });
};

export const updateCourseTopic = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.COURSE_TOPIC}${data.id}/`,
    method: "patch",
    data: data,
  });
};

export const deleteCourseTopic = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.COURSE_TOPIC}${data.id}/`,
    method: "delete",
  });
};

export const deleteCourse = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.COURSE}${data.id}/`,
    method: "delete",
  });
};

export const getTopic = async (data) => {
  data["page_size"] = 0;
  return await adminApi({
    endpoint: endpoints.COURSE_TOPIC,
    method: "get",
    params: data,
  });
};

export const getReviews = async (data) => {
  return await adminApi({
    endpoint: endpoints.REVIEWS,
    method: "get",
    params: data,
  });
};

export const getReviewsByStudents = async (data) => {
  return await adminApi({
    endpoint: endpoints.REVIEWS,
    method: "get",
    params: { is_student_review: true, ...data },
  });
};

export const createReviews = async (data) => {
  const payload = {
    ...data,
    reviewer_image: Array.isArray(data.reviewer_image)
      ? data.reviewer_image[0]
      : data.reviewer_image,
  };
  return await adminApi({
    endpoint: endpoints.REVIEWS,
    method: "post",
    data: payload,
  });
};

export const updateReviews = async (data) => {
  const payload = {
    ...data,
    reviewer_image: Array.isArray(data.reviewer_image)
      ? data.reviewer_image[0]
      : data.reviewer_image,
  };
  return await adminApi({
    endpoint: `${endpoints.REVIEWS}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getBookReviews = async (data) => {
  return await adminApi({
    endpoint: endpoints.BOOK_REVIEWS,
    method: "get",
    params: {},
  });
};

export const createBookReviews = async (data) => {
  const payload = {
    ...data,
    book: 1,
    reviewer_image: Array.isArray(data.reviewer_image)
      ? data.reviewer_image[0]
      : data.reviewer_image,
  };
  return await adminApi({
    endpoint: endpoints.BOOK_REVIEWS,
    method: "post",
    data: payload,
  });
};

export const updateBookReviews = async (data) => {
  const payload = {
    ...data,
    reviewer_image: Array.isArray(data.reviewer_image)
      ? data.reviewer_image[0]
      : data.reviewer_image,
  };
  return await adminApi({
    endpoint: `${endpoints.BOOK_REVIEWS}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const orderTopicContents = async (data) => {
  const payload = { ...data };
  return await adminApi({
    endpoint: endpoints.TOPIC_CONTENT_ORDERING,
    method: "post",
    data: payload,
  });
};
