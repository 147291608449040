import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createCategory = async (data) => {
  return await adminApi({
    endpoint: endpoints.CATEGORY,
    method: "post",
    data: data,
  });
};

export const updateCategory = async (data) => {
  const payload = {
    ...data,
  };
  return await adminApi({
    endpoint: `${endpoints.CATEGORY}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getCategory = async (data) => {
  return await adminApi({
    endpoint: endpoints.CATEGORY,
    method: "get",
    params: data,
  });
};

export const deleteCategory = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.CATEGORY}${data.id}/`,
    method: "delete",
  });
};

export const getCategoryForDropdown = async (data) => {
  const res = await adminApi({
    endpoint: endpoints.CATEGORY,
    method: "get",
    params: { page_size: 0 },
  });

  return res.data?.map((category) => ({
    value: category.id,
    label: category.name,
  }));
};
