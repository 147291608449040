import inputTypes from "constants/form/inputTypes";
import { getFaqTypeForDropdown } from "../../../services/api/queries/faq";

const faqFields = [
  {
    className: "col-5",
    inputType: inputTypes.SELECT,
    key: "faq_type",
    labelText: "Faq Type",
    placeholder: "Faq Type",
    lookupQuery: getFaqTypeForDropdown,
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "question",
    labelText: "Question",
    placeHolder: "Question",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "question_bangla",
    labelText: "Question Bangla",
    placeHolder: "Question Bangla",
    rules: { required: false },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "answer",
    labelText: "Answer",
    placeHolder: "Answer",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "answer_bangla",
    labelText: "Answer Bangla",
    placeHolder: "Answer Bangla",
    rules: { required: false },
  },
];

export default faqFields;
