import inputTypes from "../../../constants/form/inputTypes";
// import { getTemplateForDropdown } from "../../../services/api/queries/template";

export const templateFilterMeta = [
  {
    className: "col-4 min-width-20",
    inputType: inputTypes.TEXT,
    key: "search",
    labelText: "Template Name",
    // lookupQuery: getTemplateForDropdown,
    // formatCreateLabel: (userInput) => `Search for ${userInput}`,
    // createOptionPosition: "first",
  },
];
