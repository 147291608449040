import moment from "moment";
import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createCoupon,
  deleteCoupon,
  getCoupon,
  updateCoupon,
} from "../../../services/api/queries/coupon";
import couponFields from "../formFields/couponFields";

const couponListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Code Name",
        accessor: "code_name",
      },
      {
        Header: "Discount Type",
        accessor: "discount_type",
      },
      {
        Header: "Discount Amount",
        accessor: "discount_value",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY"),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateCoupon}
              fields={couponFields}
              heading={"Edit a Coupon!"}
              openerText="Edit"
              defaultValues={{
                ...defaultValues,
                start_date: moment(defaultValues.start_date).format(
                  "YYYY-MM-DD"
                ),
                end_date: moment(defaultValues.end_date).format("YYYY-MM-DD"),
                courses: defaultValues.all_courses
                  ? ["select_all"]
                  : defaultValues.courses,
              }}
              refreshEvent="refresh_table"
              size="lg"
              doNotReset={true}
              successMessage="Local agent updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteCoupon}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this category?"
              heading="Delete Coupon"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createCoupon}
        fields={couponFields}
        heading={"Create a Coupon!"}
        openerText="+ Add Coupon"
        openerClassName="btn btn-primary"
        refreshEvent="refresh_table"
        size="lg"
        successMessage="Coupon Created!"
      />,
    ],
    queryService: getCoupon,
    addButtonText: "+ Add Coupon",
  };
};

export default couponListViewMeta;
