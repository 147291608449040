import React from "react";

import { Dropdown } from "react-bootstrap";

import { Key, Settings, User } from "react-feather";

import avatar1 from "../../assets/img/avatars/avatar.jpg";
import useAuth from "hooks/useAuth";
import { CHANGE_PASS, PROFILE_PAGE } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

const NavbarUser = () => {
  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={user?.image ? user.image : avatar1}
            className="avatar img-fluid rounded-circle me-1"
            alt=""
          />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={user?.image ? user.image : avatar1}
            className="avatar img-fluid rounded-circle me-1"
            alt={user?.username}
          />
          <span className="text-dark">
            {user?.first_name
              ? `${user.first_name} ${user.last_name}`
              : user?.username}
          </span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={() => navigate(PROFILE_PAGE)}>
          <p>
            <User size={18} className="align-middle me-2" />
            Profile
          </p>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => navigate(CHANGE_PASS)}>
          <p>
            <Key size={18} className="align-middle me-2" />
            Change Password
          </p>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={(e) => signOut()}>
          <p>Sign out</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
