import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import { templateFilterMeta } from "../../../configs/meta/filterMeta/templateFilterMeta";
import templateListViewMeta from "../../../configs/meta/listViewMeta/templateListViewMeta";

const QuestionTemplate = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView
        filters={templateFilterMeta}
        meta={templateListViewMeta()}
        title="Template List"
      />
    </Container>
  </React.Fragment>
);

export default QuestionTemplate;
