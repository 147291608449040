import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import freeExamListViewMeta from "../../../configs/meta/listViewMeta/freeExamListViewMeta";

const FreeExam = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView meta={freeExamListViewMeta()} title="Free Exam List" />
    </Container>
  </React.Fragment>
);

export default FreeExam;
