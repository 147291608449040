import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createCharacteristic = async (data) => {
  return await adminApi({
    endpoint: endpoints.CHARACTERISTIC,
    method: "post",
    data: data,
  });
};

export const updateCharacteristic = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.CHARACTERISTIC}${data.id}/`,
    method: "patch",
    data: data,
  });
};

export const getCharacteristic = async (data) => {
  return await adminApi({
    endpoint: endpoints.CHARACTERISTIC,
    method: "get",
    params: data,
  });
};

export const deleteCharacteristic = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.CHARACTERISTIC}${data.id}/`,
    method: "delete",
  });
};
