import axios, { axiosPublicInstance } from "services/api/axios";
import { handleError } from "services/api/errorHandler";

const apiClient = (defaultConfigs, instance) => async (requestConfig) => {
  try {
    const response = await instance.request({
      // ...defaultConfigs,
      url: `${defaultConfigs.url}${requestConfig.endpoint}`,
      method: requestConfig.method,
      data: requestConfig.data,
      params: requestConfig.params,
      headers: {
        ...defaultConfigs?.headers,
        ...requestConfig.headers,
      },
    });

    if (!response) throw response;
    return {
      code: response?.status,
      success: true,
      data: response?.data?.data ? response.data.data : response?.data,
      error: response.error,
      message: response.data?.message,
      meta: response.data?.meta_data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const publicApi = apiClient(
  { url: process.env.REACT_APP_API_BASE_URL },
  axiosPublicInstance
);

export const adminApi = apiClient(
  {
    url: process.env.REACT_APP_API_BASE_URL,
    // headers: { ...(token && { Authorization: `Bearer ${token} ` }) },
  },
  axios
);
