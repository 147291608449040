import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ListView from "../../../components/listView/ListView";
import subscriptionListViewMeta from "../../../configs/meta/listViewMeta/subcriptionListViewMeta";

const Subscription = () => (
  <React.Fragment>
    <Helmet title="Subscription Page" />
    <Container fluid className="p-0">
      <ListView meta={subscriptionListViewMeta()} title="Subscription List" />
    </Container>
  </React.Fragment>
);

export default Subscription;
