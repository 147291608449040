import inputTypes from "constants/form/inputTypes";

const optionUpdateFields = [
  {
    className: "row list-input",
    inputType: inputTypes.QUESTION_INPUT,
    key: "options",
    labelPrefix: "Options",
    addButtonText: "+ Add an Option",
    addButtonClassName: "mt-3",
    listItemClassName: "row",
    dependencies: ["q_type"],
    textDisabled: true,
    onDependencyValueChange: ({ q_type }, key, { toggleVisibility }) => {
      toggleVisibility("options", q_type === "written");
    },
  },
];

export default optionUpdateFields;
