import inputTypes from "constants/form/inputTypes";
import { getCourseForDropdown } from "../../../services/api/queries/course";
import { getTemplateForDropdown } from "../../../services/api/queries/template";

const freeExamFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Name",
    placeHolder: "Enter Exam Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "instruction",
    labelText: "Instructions",
    placeHolder: "Enter Instructions",
    rules: { required: true },
  },
  {
    className: "col-5",
    inputType: inputTypes.SELECT,
    key: "template",
    labelText: "Template",
    placeholder: "Template Name",
    lookupQuery: getTemplateForDropdown,
    rules: { required: true },
  },
  {
    className: "mb-3",
    childrenClassName: "d-flex",
    inputType: inputTypes.RADIO,
    key: "duration_select",
    labelText: "Quiz Duration",
    rules: { required: true },
    options: [
      {
        label: "Time Duration",
        value: "time_duration",
      },
      {
        label: "No Limit",
        value: "no_limit",
      },
    ],
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.NUMBER,
    key: "hours",
    placeHolder: "Enter Hours",
    hide: true,
    labelText: "Enter Hours",
    dependencies: ["duration_select"],
    onDependencyValueChange: (
      { duration_select },
      key,
      { toggleVisibility }
    ) => {
      toggleVisibility("hours", duration_select !== "time_duration");
    },
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.NUMBER,
    key: "minutes",
    placeHolder: "Enter Minutes",
    hide: true,
    labelText: "Enter Minutes",
    dependencies: ["duration_select"],
    onDependencyValueChange: (
      { duration_select },
      key,
      { toggleVisibility }
    ) => {
      toggleVisibility("minutes", duration_select !== "time_duration");
    },
  },
  {
    className: "mb-3",
    inputType: inputTypes.SELECT,
    key: "suggested_courses",
    placeHolder: "Select Courses",
    labelText: "Select Courses",
    isMulti: true,
    multiSelectAll: true,
    lookupQuery: getCourseForDropdown,
  },
];

export default freeExamFields;
