import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const createNote = async (data) => {
  const payload = {
    ...data,
    document: data.document[0],
    order_in_topic: 1000,
  };
  return await adminApi({
    endpoint: endpoints.CLASS_NOTE,
    method: "post",
    data: payload,
  });
};

export const getNoteById = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.CLASS_NOTE}${data.id}/`,
    method: "get",
  });
};

export const updateNote = async (data) => {
  const payload = {
    ...data,
    document: Array.isArray(data.document) ? data.document[0] : data.document,
  };
  return await adminApi({
    endpoint: `${endpoints.CLASS_NOTE}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const deleteNote = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.CLASS_NOTE}${data.id}/`,
    method: "delete",
  });
};
