import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../../../components/Loader";

import Header from "./Header";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import Projects from "./Projects";
import Statistics from "./Statistics";
import {
  getDashboardData,
  getDashboardEnrollmentData,
} from "../../../services/api/queries/dashboard";

const Default = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [sales, setSales] = useState([]);

  const performQuery = useCallback((duration_type) => {
    setIsLoading(true);
    const res = getDashboardData({ duration_type }).then((info) => {
      const { data } = info;
      setDashboardData(data);
      setIsLoading(false);
      return info;
    });
    return res;
  }, []);

  const performSalesQuery = useCallback((duration_type) => {
    setIsLoading(true);
    const res = getDashboardEnrollmentData({ duration_type }).then((info) => {
      const { data } = info;
      setSales(data);
      setIsLoading(false);
      return info;
    });
    return res;
  }, []);

  useEffect(() => {
    performSalesQuery();
  }, [performSalesQuery]);
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      {/* <Loader isLoading={isLoading}> */}
      <Container fluid className="p-0">
        <Header performQuery={performQuery} />
        <Statistics data={dashboardData?.card_stats} />

        <Row>
          <Col md="8" className="d-flex">
            <BarChart
              chartData={sales?.monthly_enrollment}
              toolTipData={sales?.monthly_enrollment}
            />
          </Col>
          <Col md="4" className="d-flex">
            <PieChart pieData={dashboardData?.enrollment_by_type} />
          </Col>
        </Row>
        <Projects data={sales?.enrollments} />
      </Container>
      {/* </Loader> */}
    </React.Fragment>
  );
};
export default Default;
