import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createCoupon = async (data) => {
  const payload = {
    ...data,
    courses: data.courses?.includes("select_all") ? null : data.courses,
    all_courses: data.courses?.includes("select_all"),
  };
  return await adminApi({
    endpoint: endpoints.COUPON,
    method: "post",
    data: payload,
  });
};

export const updateCoupon = async (data) => {
  const payload = {
    ...data,
    courses: data.courses?.includes("select_all") ? null : data.courses,
    all_courses: data.courses?.includes("select_all"),
  };
  return await adminApi({
    endpoint: `${endpoints.COUPON}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getCoupon = async (data) => {
  return await adminApi({
    endpoint: endpoints.COUPON,
    method: "get",
    params: data,
  });
};

export const getCouponUsages = async (data) => {
  return await adminApi({
    endpoint: endpoints.COUPON_USAGES,
    method: "get",
    params: data,
  });
};

export const deleteCoupon = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.COUPON}${data.id}/`,
    method: "delete",
  });
};
