import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classNoteFields from "../../configs/meta/formFields/classNoteFields";
import lectureFields from "../../configs/meta/formFields/lectureFields";
import quizFields from "../../configs/meta/formFields/quizFields";
import {
  deleteExam,
  getExamById,
  updateExam,
} from "../../services/api/queries/exams";
import {
  deleteLecture,
  getLectureById,
  updateLecture,
} from "../../services/api/queries/lectures";
import {
  deleteNote,
  getNoteById,
  updateNote,
} from "../../services/api/queries/notes";
import ConfirmActionButton from "../actions/ConfirmActionButton";
import ModalFormOpener from "../actions/ModalFormOpener";
import moment from "moment";

const TopicItem = ({ item, refreshEvent }) => {
  const getExamDefaultValues = () => ({
    ...item,
    publish_select: item.result_publish_date ? "publish_time" : "instant",
    duration_select: item.duration ? "time_duration" : "no_limit",
    quiz_attempts: item.attempt_limit ? "limited" : "unlimited",
    start_select: item.start_date ? "date_time" : "instant",
    hours: item.duration ? parseInt(item.duration?.split(":")?.[0]) : null,
    minutes: item.duration ? parseInt(item.duration?.split(":")?.[1]) : null,
    template: item.template_id,
    start_date: moment(item.start_date).format("YYYY-MM-DD[T]HH:mm:ss"),
  });

  const getLectureTopics = () => {
    return lectureFields.map((field) => {
      if (field.key === "video_url")
        return { ...field, hide: item.l_type === "live_class" };
      if (field.key === "joining_link" || field.key === "joining_date")
        return { ...field, hide: item.l_type === "pre_recorded" };
      return field;
    });
  };

  const getExamTopic = () => {
    const examItem = getExamDefaultValues();
    return quizFields.map((field) => {
      if (field.key === "hours" || field.key === "minutes")
        return { ...field, hide: examItem.duration_select === "no_limit" };
      if (field.key === "start_date")
        return { ...field, hide: examItem.start_select === "instant" };
      if (field.key === "result_publish_date")
        return { ...field, hide: examItem.publish_select === "instant" };
      if (field.key === "attempt_limit")
        return { ...field, hide: examItem.quiz_attempts === "unlimited" };
      return field;
    });
  };

  return (
    <div className="w-100 d-flex justify-content-between">
      <h4>{item.name}</h4>
      <span>
        <ModalFormOpener
          submitService={
            item.content_type === "lecture"
              ? updateLecture
              : item.content_type === "exam"
              ? updateExam
              : updateNote
          }
          queryService={
            item.content_type === "lecture"
              ? getLectureById
              : item.content_type === "exam"
              ? getExamById
              : getNoteById
          }
          fields={
            item.content_type === "lecture"
              ? getLectureTopics()
              : item.content_type === "exam"
              ? getExamTopic()
              : classNoteFields
          }
          heading={`Edit a ${item.content_type}!`}
          openerText={<FontAwesomeIcon icon={faEdit} />}
          refreshEvent={refreshEvent}
          size="lg"
          successMessage={`${item.content_type} updated!`}
          appendPayload={{
            id: item.id,
          }}
          openerClassName="d-inline-block me-3 cursor-pointer"
          defaultValues={
            item.content_type === "exam"
              ? getExamDefaultValues()
              : item.content_type === "lecture"
              ? {
                  ...item,
                  instructors: item?.instructor_list?.[0]
                    ? item.instructor_list
                    : [],
                }
              : { ...item }
          }
        />
        <ConfirmActionButton
          openerText={<FontAwesomeIcon icon={faTrash} />}
          openerClassName="d-inline-block me-3 cursor-pointer"
          confirmButtonName="Yes"
          refreshEvent={refreshEvent}
          queryService={
            item.content_type === "lecture"
              ? deleteLecture
              : item.content_type === "exam"
              ? deleteExam
              : deleteNote
          }
          value={item}
          confirmationMessage="Do you really wanna delete this?"
          heading={`Delete ${item.content_type}!`}
        />
      </span>
    </div>
  );
};

export default TopicItem;
