export const BLOG = `/pages/blogs/blog`;
export const BLOG_CATEGORY = `/pages/blogs/category`;
export const CATEGORY_PAGE = `/pages/course/category`;
export const CHANGE_PASS = `/pages/change-password`;
export const CHARACTERISTIC_PAGE = `/pages/characteristic`;
export const CIRCULAR_PAGE = `/pages/circulars`;
export const COUPON_PAGE = `/pages/coupon`;
export const COUPON_USAGE_PAGE = `/pages/coupon-usage`;
export const COURSE_PAGE = `/pages/courses`;
export const COURSE_BUILDER_PAGE = `/pages/course/course-builder`;
export const DASHBOARD = `/`;
export const DISCUSSION_PAGE = `/discussions/forum/:course_id/:forum_id`;
export const ENROLMENT_PAGE = `/pages/manage-enrolment/enrolment`;
export const FREE_EXAM = `/pages/free-exams`;
export const FREE_EXAM_PARTICIPANTS = `/pages/free-exam-participants`;
export const INSTRUCTOR_PAGE = "/pages/instructor";
export const JOIN_DISCUSSIONS = `/pages/answer-questions`;
export const MANAGE_SALES = `/pages/sales`;
export const NOTICE_PAGE = "/pages/notice";
export const PROFILE_PAGE = `/profile`;
export const QUESTION_DETAILS = `/discussions/answers/:course_id/:question_id`;
export const QUESTION_PAGE = `/pages/quiz-management/question`;
export const REGULAR_ENROLMENT_PAGE = `/pages/manage-enrolment/regular-enrolment`;
export const RESET_PASSWORD = `/pages/auth/reset-password`;
export const REVIEW_PAGE = "/pages/review";
export const SECTION_PAGE = `/pages/quiz-management/section`;
export const SIGN_IN = `/auth/sign-in`;
export const SITE_INFO_PAGE = `/pages/site-info`;
export const STUDENTS_PAGE = `/pages/students`;
export const STUDENTS_REVIEW = `/pages/students-review`;
export const BOOK_ORDER = `/pages/book-orders`;
export const BOOK_REVIEW = `/pages/book-reviews`;
export const SUB_CATEGORY_PAGE = `/pages/course/sub-category`;
export const SUBSCRIPTION = `/pages/subscription`;
export const TEMPLATE_PAGE = `/pages/quiz-management/template`;
export const UNANSWERED_QUESTIONS = `/pages/unanswered-questions`;
export const USER_MANAGEMENT = `/pages/user-management`;
export const VIEW_INVOICE = `/pages/view-invoice/:invoice`;
export const VIEW_TEMPLATE = `/pages/view-template/:id`;
export const WRITTEN_EVALUATION_PAGE = `/pages/written-evaluation`;
export const FAQ_TYPE = `/pages/faq-types`;
export const FAQ = `/pages/faqs`;
