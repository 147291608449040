import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ListView from "../../../components/listView/ListView";
import { enrolmentFilterMeta } from "../../../configs/meta/filterMeta/enrolmentFilterMeta";
import enrolmentListViewMeta from "../../../configs/meta/listViewMeta/enrolmentListViewMeta";

const Enrolment = () => (
  <React.Fragment>
    <Helmet title="Offline Enrolment Page" />
    <Container fluid className="p-0">
      <ListView
        filters={enrolmentFilterMeta}
        meta={enrolmentListViewMeta()}
        title="Offline Enrolment List"
      />
    </Container>
  </React.Fragment>
);

export default Enrolment;
