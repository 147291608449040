const inputTypes = {
  CHECKBOX: "checkbox",
  COUNTER: "counter",
  CHILDREN_COUNTER: "children_counter",
  CREATABLE_SELECT: "creatable_select",
  CUSTOM: "custom",
  DATE: "date",
  DATE_TIME: "datetime-local",
  DATE_RANGE: "date_range",
  FILE: "file",
  IMAGE: "image",
  LIST_INPUT: "list",
  NUMBER: "number",
  PASSWORD: "password",
  POPOVER_LIST_INPUT: "popover_list",
  QUESTION_INPUT: "question_input",
  RADIO: "radio",
  RICH_TEXT: "rich_text",
  SELECT: "select",
  TEXT: "text",
  TEXTAREA: "textarea",
  SEARCH: "search",
};

export default inputTypes;
