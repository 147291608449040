import React from "react";
import { Container } from "react-bootstrap";
import ListView from "components/listView/ListView";
import noticeListViewMeta from "configs/meta/listViewMeta/noticeListViewMeta";
import { NoticeFilterMeta } from "configs/meta/filterMeta/noticeFilterMeta";

const Notice = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView
        filters={NoticeFilterMeta}
        meta={noticeListViewMeta()}
        title="Notice List"
      />
    </Container>
  </React.Fragment>
);

export default Notice;
