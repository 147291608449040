import inputTypes from "constants/form/inputTypes";

const answerFields = [
  {
    className: "mb-3 col-12 d-inline-block",
    inputType: inputTypes.TEXTAREA,
    key: "answer",
    labelText: "Answer",
    placeHolder: "Enter Answer",
    rules: { required: true },
  },
  {
    className: "pb-3",
    inputType: inputTypes.FILE,
    key: "image",
    labelText: "Image:",
    multiple: false,
  },
];

export default answerFields;
