import inputTypes from "constants/form/inputTypes";

const userFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "first_name",
    labelText: "First Name",
    placeHolder: "Enter Your First Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "last_name",
    labelText: "Last Name",
    placeHolder: "Enter Your Last Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "username",
    labelText: "Username",
    placeHolder: "Enter Your Username",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "email",
    labelText: "Your Email",
    placeHolder: "Enter Your Email",
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "phone_number",
    labelText: "Your Phone Number",
    placeHolder: "Enter Your Phone Number",
  },
  {
    className: "mb-3",
    inputType: inputTypes.PASSWORD,
    key: "password",
    labelText: "Your Password",
    placeHolder: "Enter Your Password",
  },
  {
    className: "col-3 min-width-20",
    inputType: inputTypes.SELECT,
    key: "role",
    labelText: "User Type",
    options: [
      {
        label: "Admin",
        value: "admin",
      },
      {
        label: "Content-Creator",
        value: "content_creator",
      },
    ],
  },
];

export default userFields;
