import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const createFreeExam = async (data) => {
  const payload = {
    ...data,
    duration:
      data.duration_select === "time_duration"
        ? `${data.hours || 0}:${data.minutes || 0}`
        : null,
  };
  return await adminApi({
    endpoint: endpoints.FREE_EXAMS,
    method: "post",
    data: payload,
  });
};

export const getFreeExamById = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.FREE_EXAMS}${data.id}/`,
    method: "get",
  });
};

export const updateFreeExam = async (data) => {
  const payload = {
    ...data,
    duration:
      data.duration_select === "time_duration"
        ? `${data.hours || 0}:${data.minutes || 0}`
        : null,
  };
  return await adminApi({
    endpoint: `${endpoints.FREE_EXAMS}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getFreeExams = async (data) => {
  return await adminApi({
    endpoint: endpoints.FREE_EXAMS,
    method: "get",
    params: data,
  });
};

export const deleteFreeExam = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.FREE_EXAMS}${data.id}/`,
    method: "delete",
  });
};

export const getFreeExamParticipants = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.FREE_EXAMS}participants/`,
    method: "get",
    params: data,
  });
};

export const downloadExamParticipantReport = async (data) => {
  console.log(data);
  return await adminApi({
    endpoint: `${endpoints.FREE_EXAMS}participants/`,
    method: "get",
    params: data,
  });
};
