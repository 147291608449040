import inputTypes from "constants/form/inputTypes";

const instructorFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Instructor Name",
    placeHolder: "Enter Instructor Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name_bn",
    labelText: "Instructor Name (Bangla)",
    placeHolder: "Enter Instructor Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "designation",
    labelText: "Designation",
    placeHolder: "Enter Designation",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "designation_bn",
    labelText: "Designation Bangla",
    placeHolder: "Enter Designation In Bangla",
    rules: { required: true },
  },
  {
    className: "pb-3",
    inputType: inputTypes.FILE,
    key: "image",
    labelText: "Image:",
    multiple: false,
  },
  {
    className: "mb-3",
    inputType: inputTypes.CHECKBOX,
    key: "show_in_landing",
    labelText: "Show in Landing Page",
  },
];

export default instructorFields;
