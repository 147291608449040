import React from "react";
import { Container } from "react-bootstrap";
import ListView from "../../../components/listView/ListView";
import { subCategoryFilterMeta } from "../../../configs/meta/filterMeta/subCategoryFilterMeta";
import subCategoryListViewMeta from "../../../configs/meta/listViewMeta/subCategoryListViewMeta";

const SubCategory = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <ListView
        filters={subCategoryFilterMeta}
        meta={subCategoryListViewMeta()}
        title="Sub-category List"
      />
    </Container>
  </React.Fragment>
);

export default SubCategory;
