import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createSiteInfo = async (data) => {
  const payload = {
    ...data,
    banner_image: data.banner_image[0],
    social_links: {
      facebook: data.facebook,
      instagram: data.instagram,
      youtube: data.youtube,
      tiktok: data.tiktok,
      linkedin: data.linkedin,
    },
  };
  return await adminApi({
    endpoint: endpoints.SITE_INFO,
    method: "post",
    data: payload,
  });
};

export const updateSiteInfo = async (data) => {
  const payload = {
    ...data,
    banner_image: Array.isArray(data.banner_image)
      ? data.banner_image[0]
      : data.banner_image,
    social_links: {
      facebook: data.facebook,
      instagram: data.instagram,
      youtube: data.youtube,
      tiktok: data.tiktok,
      linkedin: data.linkedin,
    },
  };
  return await adminApi({
    endpoint: `${endpoints.SITE_INFO}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getSiteInfo = async (data) => {
  return await adminApi({
    endpoint: endpoints.SITE_INFO,
    method: "get",
    params: data,
  });
};

export const hasSiteInfo = async (data) => {
  const res = await adminApi({
    endpoint: endpoints.SITE_INFO,
    method: "get",
    params: data,
  });
  return !res?.data?.length;
};

export const getSubscription = async (data) => {
  return await adminApi({
    endpoint: endpoints.SUBSCRIPTION,
    method: "get",
    params: data,
  });
};

export const deleteSiteInfo = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.SITE_INFO}${data.id}/`,
    method: "delete",
  });
};
